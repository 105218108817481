<template>
  <div :class="infoTheme && 'background-info'">
    <div>
      {{ $t('main.ad_form.url_information.header') }}
    </div>
    <br />
    <ul>
      <li v-for="(information, index) in informationLists" :key="index">
        {{ information }}
      </li>
    </ul>
    <br />
    <span>{{ $t('main.ad_form.url_information.footer') }}</span>
  </div>
</template>

<script>
  export default {
    name: 'UrlRequirement',
    props: {
      infoTheme: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      informationLists() {
        return [
          this.$t('main.ad_form.url_information.token'),
          this.$t('main.ad_form.url_information.extraneous_spaces'),
          this.$t('main.ad_form.url_information.query'),
          this.$t('main.ad_form.url_information.slash'),
          this.$t('main.ad_form.url_information.url_starts')
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .background-info {
    background-color: rgba(17, 130, 201, 0.06);
  }
</style>
