<template>
  <div>
    <c-textarea
      :selenium-id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_URL, creativeIndex)"
      :value="creative.vastTagUri"
      :error-messages="errors[`creatives.${creativeIndex}.vast_tag`]"
      hide-details="auto"
      :label="$t('main.ad_form.vast_tag_url')"
      :placeholder="$t('main.ad_form.vast_tag_url')"
      class="mb-2"
      label-bold
      @focus="removeError(`creatives.${creativeIndex}.vast_tag`)"
      @input="$emit('update-creative', { value: $event, name: 'vastTagUri' })"
    />
    <ad-formats-url-tokens
      v-if="creativeTokensLinks.length"
      :value="creative.vastTagUri"
      :tokens="creativeTokensLinks"
      class="mb-4"
      @input="$emit('update-creative', { value: $event, name: 'vastTagUri' })"
    />
    <creative-priority
      v-if="rotationIsRandom"
      :creative-index="creativeIndex"
      :creative="creative"
      @input="$emit('update-creative', { value: $event, name: 'priority' })"
      @focus="removeError(`creatives.${creativeIndex}.priority`)"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CTextarea from '@clickadilla/components/ui/CTextarea.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import AdFormatsUrlTokens from '@/components/ads/AdFormatsUrlTokens/Index.vue'
  import CreativePriority from '@/components/ads/CreativePriority.vue'

  export default {
    components: {
      CTextarea,
      CreativePriority,
      AdFormatsUrlTokens
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['errors']),
      ...mapGetters('adForm', ['rotationIsRandom', 'creativeTokensLinks'])
    },
    methods: {
      ...mapActions('adForm', ['removeError']),
      seleniumDynamicField
    }
  }
</script>
