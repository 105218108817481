<template>
  <c-tabs-toggle-buttons
    :title="title"
    :value="adTypeIndex"
    :buttons="adTypes"
    :selenium-id="seleniumId"
    @change="$emit('change', $event)"
  />
</template>

<script>
  import CTabsToggleButtons from '@clickadilla/components/ui/CTabsToggleButtons.vue'

  export default {
    name: 'AdRotationTab',
    components: {
      CTabsToggleButtons
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      adTypes: {
        type: Array,
        default: () => []
      },
      backgroundColor: {
        type: String,
        default: 'transparent'
      },
      adTypeIndex: {
        type: Number,
        default: 0
      },
      seleniumId: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-tab:not(.v-tab--active) {
    color: var(--v-black-base);
    background: var(--v-white-base);
  }
</style>
