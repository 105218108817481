<template>
  <div>
    <c-text-field
      :value="motionBannerParams.titleText"
      :placeholder="$t('main.ad_form.motion_banner.title_text')"
      :error-messages="errors[getErrorKey('title_text')]"
      hide-details="auto"
      class="mb-3"
      :label="$t('main.ad_form.motion_banner.title_text')"
      label-bold
      @input="setTitleSettings({ value: $event, name: 'titleText' })"
    />

    <c-text-field
      :value="motionBannerParams.buttonText"
      :placeholder="$t('main.ad_form.motion_banner.button_text')"
      :error-messages="errors[getErrorKey('button_text')]"
      hide-details="auto"
      class="mb-3"
      :label="$t('main.ad_form.motion_banner.button_text')"
      label-bold
      @input="setTitleSettings({ value: $event, name: 'buttonText' })"
    />

    <c-textarea
      :value="motionBannerParams.descriptionText"
      :placeholder="$t('main.ad_form.motion_banner.description_text')"
      :error-messages="errors[getErrorKey('description_text')]"
      hide-details="auto"
      class="mb-3"
      :label="$t('main.ad_form.motion_banner.description_text')"
      rows="3"
      label-bold
      @input="setTitleSettings({ value: $event, name: 'descriptionText' })"
    />
    <c-textarea
      :value="motionBannerParams.additionalText"
      :placeholder="$t('main.ad_form.motion_banner.additional_text')"
      :error-messages="errors[getErrorKey('additional_text')]"
      hide-details="auto"
      class="mb-3"
      :label="$t('main.ad_form.motion_banner.additional_text')"
      rows="3"
      label-bold
      @input="setTitleSettings({ value: $event, name: 'additionalText' })"
    />
  </div>
</template>

<script>
  import CTextarea from '@clickadilla/components/ui/CTextarea.vue'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'TitleSettings',
    components: {
      CTextarea,
      CTextField
    },
    props: {
      motionBannerParams: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapState('adForm', ['errors'])
    },
    methods: {
      ...mapActions('adForm', ['setMotionBannerParams']),
      setTitleSettings({ value, name }) {
        const motionBannerParamsCopy = JSON.parse(JSON.stringify(this.motionBannerParams))
        motionBannerParamsCopy[name] = value

        this.setMotionBannerParams({
          creativeIndex: this.creativeIndex,
          motionBannerParams: motionBannerParamsCopy
        })
      },
      getErrorKey(key) {
        return `creatives.${this.creativeIndex}.motion_banner_params.${key}`
      }
    }
  }
</script>
