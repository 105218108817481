<template>
  <push-preview
    :creatives="creatingPreview"
    :available-platforms="availablePlatforms"
    :buttons-are-shown="buttonsAreShown"
  />
</template>

<script>
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import PushPreview from '@/components/PushPreview/PushPreview.vue'
  import PushCreative from '@/services/classes/creatives/PushCreative.js'

  export default {
    name: 'PushCreativePreview',
    components: {
      PushPreview
    },
    props: {
      adFormat: {
        type: String,
        required: true
      },
      creative: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        fetchedIconUrl: '',
        fetchedImageUrl: ''
      }
    },
    computed: {
      creatingPreview() {
        return [
          new PushCreative({
            image: {
              src: this.creative.image ? this.creative.image.src : ''
            },
            icon: {
              src: this.creative.icon ? this.creative.icon.src : ''
            },
            body: this.creative.body || this.creative.description,
            title: this.creative.title,
            url: this.creative.url,
            button_one: this.creative.buttonOne,
            button_two: this.creative.buttonTwo
          })
        ]
      },
      availablePlatforms() {
        return (
          {
            [adFormats.WEB_PUSH]: ['mac', 'android', 'windows'],
            [adFormats.IN_PAGE_AD]: ['ios', 'android', 'windows']
          }[this.adFormat] || []
        )
      },
      buttonsAreShown() {
        return [adFormats.WEB_PUSH].includes(this.adFormat)
      }
    }
  }
</script>
