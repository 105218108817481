<template>
  <div>
    <ad-rotation-tab
      v-if="!isMobile"
      :title="title"
      :ad-type-index="interstitialDevicesIndex"
      :background-color="backgroundColor"
      :ad-types="interstitialsDevices"
      :selenium-id="seleniumIds.SELENIUM_TEST_AD_FORM_SIZE"
      @change="$emit('change', $event)"
    />
    <c-select
      v-else
      :id="seleniumIds.SELENIUM_TEST_AD_FORM_SIZE"
      :value="interstitialDevicesIndex"
      :items="interstitialsDevices"
      item-text="text"
      item-value="value"
      :label="title"
      label-bold
      hide-details
      background-color="white"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import { interstitialSizes } from '@clickadilla/components/constants/ad-sezes.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import AdRotationTab from '@/components/ads/AdRotationTab.vue'

  export default {
    name: 'AdsDeviceType',
    components: {
      AdRotationTab,
      CSelect
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      interstitialsSizes: {
        type: Array,
        required: true
      },
      backgroundColor: {
        type: String,
        default: 'transparent'
      },
      isDisabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['size']),
      interstitialsDevices() {
        return this.interstitialsSizes.map((size, index) => ({
          text: this.interstitialsLabelDevice[size] || size,
          value: index
        }))
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      interstitialsLabelDevice() {
        return {
          [interstitialSizes.DESKTOP_SIZE]: this.$t('main.ad_form.desktop'),
          [interstitialSizes.MOBILE_SIZE]: this.$t('main.ad_form.mobile')
        }
      },
      interstitialDevicesIndex() {
        return this.interstitialsSizes.indexOf(this.size)
      }
    },
    created() {
      if (!this.size) {
        this.$emit('change', 0)
      }
    }
  }
</script>
