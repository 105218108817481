<template>
  <div>
    <template v-if="canCreateSelfCode">
      <span class="text-subtitle-2 black--text text-center pr-2">Selfcode</span>

      <c-textarea
        :id="seleniumIds.SELENIUM_TEST_AD_FORM_SELFCODE"
        :value="code"
        outlined
        background-color="white"
        :error-messages="errors.code"
        @input="setCode($event)"
        @focus="removeError('code')"
      />
    </template>

    <template v-else>
      {{ selfCodePleaseContact }}
      <live-support-string />
      {{ selfCodeForTheFurther }}
    </template>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CTextarea from '@clickadilla/components/ui/CTextarea.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import LiveSupportString from '@/components/LiveSupportString.vue'

  export default {
    name: 'AdSelfCode',
    components: {
      LiveSupportString,
      CTextarea
    },
    props: {
      selfCodePleaseContact: {
        type: String,
        required: true
      },
      selfCodeForTheFurther: {
        type: String,
        required: true
      },
      permission: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['code', 'errors']),
      authUserPermissions() {
        return this.$auth.user?.permissions ?? []
      },
      canCreateSelfCode() {
        if (!Array.isArray(this.authUserPermissions)) {
          return false
        }

        return this.authUserPermissions.includes(this.permission)
      }
    },
    methods: {
      ...mapActions('adForm', ['setCode', 'removeError'])
    }
  }
</script>
