<template>
  <v-row class="flex-wrap black rounded pa-0 py-sm-10 px-sm-8 ma-1">
    <v-col class="ml-0 ml-xl-3 px-3 px-sm-0">
      <div class="custom-title pt-1">
        {{ $t('main.ad_form.selection_platform') }}
      </div>

      <agent-select
        v-model="selectedAgent"
        :options-platform="optionsPlatform"
        @platform-handler="platformType = $event"
      />
    </v-col>

    <v-col class="mr-0 mr-xl-3 px-3 px-sm-0">
      <div class="custom-title pt-1">
        {{ $t('main.ad_form.preview_banner') }}
      </div>

      <div class="d-flex flex-column align-center justify-center preview-container my-1 pa-2">
        <template v-if="['mac', 'ios'].includes(selectedAgent)">
          <apple-push
            v-for="creative in creatives"
            :key="creative.id"
            :creative="creative"
            :buttons-are-shown="buttonsAreShown"
          />
        </template>

        <template v-if="selectedAgent === 'android'">
          <android-push
            v-for="creative in creatives"
            :key="creative.id"
            :creative="creative"
            :platform-type="platformType"
            :buttons-are-shown="buttonsAreShown"
          />
        </template>

        <template v-if="selectedAgent === 'windows'">
          <windows-push
            v-for="creative in creatives"
            :key="creative.id"
            :creative="creative"
            :platform-type="platformType"
            :buttons-are-shown="buttonsAreShown"
          />
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import AgentSelect from './AgentSelect.vue'
  import ApplePush from '@/components/PushPreview/ApplePush.vue'
  import AndroidPush from '@/components/PushPreview/AndroidPush.vue'
  import WindowsPush from '@/components/PushPreview/WindowsPush.vue'

  export default {
    name: 'PushPreview',
    components: {
      AgentSelect,
      ApplePush,
      AndroidPush,
      WindowsPush
    },
    props: {
      creatives: {
        type: Array,
        required: true
      },
      availablePlatforms: {
        type: Array,
        required: true
      },
      buttonsAreShown: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        platformType: '',
        selectedAgent: 'android',
        platforms: [
          { value: 'ios', text: 'IOS (CHROME)' },
          { value: 'mac', text: 'MAC' },
          {
            value: 'android',
            text: 'ANDROID',
            btnOptions: [
              { value: 'expanded', text: 'expanded' },
              { value: 'collapsed', text: 'collapsed' }
            ]
          },
          {
            value: 'windows',
            text: 'WINDOWS',
            btnOptions: [
              { value: 'win7810pro', text: '7, 8, 10 Pro' },
              { value: 'win10home', text: '10 Home' }
            ]
          }
        ]
      }
    },
    computed: {
      optionsPlatform() {
        return this.platforms.filter(({ value }) => this.availablePlatforms.includes(value))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .custom-title {
    color: var(--v-secondary-base) !important;
    font-size: 16px;
    padding-bottom: 8px;
  }
  .preview-container {
    display: flex;
    align-content: center;
    width: 330px;
    max-width: 100%;
    min-width: 100%;
    border: 2px dashed var(--v-secondary-darken-base);
    border-radius: 8px;
    min-height: 200px;
  }
</style>
