<template>
  <div>
    <c-text-field
      :id="seleniumId"
      :background-color="backgroundColor"
      :value="url"
      :loading="isLoading"
      :error-messages="adErrorsAdFormat"
      :placeholder="placeholder"
      label-bold
      hide-details="auto"
      @input="$emit('input', $event)"
      @focus="$emit('focus')"
    >
      <template #label>
        <div class="d-flex align-center mb-1">
          <div class="text-subtitle-2 black--text text-center pr-2">
            URL
            <span v-if="!isNotCreative"> #{{ creativeIndex + 1 }}</span>
          </div>

          <creative-info-icon :link="helpUrls.token_for_tracking">
            <url-requirement />
          </creative-info-icon>
        </div>
      </template>
    </c-text-field>
    <v-expand-transition>
      <div v-if="adErrorsAdFormat">
        <url-requirement
          class="mt-3 mb-6 py-3 px-4 rounded info--text"
          info-theme
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapGetters, mapState } from 'vuex'
  import CreativeInfoIcon from '@/components/CreativeInfoIcon.vue'
  import UrlRequirement from '@/components/ads/UrlRequirement.vue'

  export default {
    name: 'AdUrlField',
    components: {
      UrlRequirement,
      CreativeInfoIcon,
      CTextField
    },
    props: {
      url: {
        type: String,
        required: true
      },
      isNotCreative: {
        type: Boolean,
        default: false
      },
      creativeIndex: {
        type: Number,
        default: 0
      },
      placeholder: {
        type: String,
        default: ''
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      backgroundColor: {
        type: String,
        default: 'secondary-lighten'
      },
      seleniumId: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapState('adForm', ['errors']),
      ...mapGetters('settings', ['helpUrls']),
      adErrorsAdFormat() {
        return this.isNotCreative
          ? this.errors.url || this.errors.content_feed_url
          : this.errors[`creatives.${this.creativeIndex}.url`] || this.errors['creatives.url']
      }
    }
  }
</script>
