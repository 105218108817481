<template>
  <div>
    <div class="d-flex align-center black--text font-weight-medium">
      <span class="mr-2">
        {{ $t('main.ad_form.tokens') }}
      </span>

      <creative-info-icon :link="helpUrls.token_for_tracking">
        <p>{{ $t('main.ad_form.token_parameters') }}</p>
        <span>{{ $t('main.ad_form.token_more') }}</span>
      </creative-info-icon>
    </div>

    <url-tokens-query-builder
      class="mx-0"
      :is-light="isLight"
      :value="value"
      :tokens="tokens"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import UrlTokensQueryBuilder from '@/components/ads/AdFormatsUrlTokens/UrlTokensQueryBuilder.vue'
  import CreativeInfoIcon from '@/components/CreativeInfoIcon.vue'

  export default {
    name: 'AdFormatsUrlTokens',
    components: {
      UrlTokensQueryBuilder,
      CreativeInfoIcon
    },
    props: {
      value: {
        type: String,
        required: true
      },
      tokens: {
        type: Array,
        required: true
      },
      isLight: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters('settings', ['helpUrls'])
    }
  }
</script>
