<template>
  <div>
    <c-select
      :id="seleniumIds.SELENIUM_TEST_AD_FORM_SKIP_TIME"
      :value="skipTimeIndex"
      :items="skipTimeOptionsMapping"
      item-value="value"
      item-text="label"
      :class="{ 'skip-time-select': !isMobile }"
      :label="$t('main.ad_form.skip_time')"
      :placeholder="$t('main.ad_form.skip_time')"
      label-bold
      hide-details
      background-color="white"
      @change="changeSkipTime($event)"
    />

    <div class="my-3 d-flex align-center">
      <v-icon size="18" color="info" class="pr-2">
        mdi-information-outline
      </v-icon>
      <span class="info--text">{{ $t('main.ad_form.skip_time_info') }}</span>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'SelectSkipTime',
    components: { CSelect },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapGetters('settings', ['skipTimeOptions']),
      ...mapState('adForm', ['skipTime']),
      skipTimeOptionsMapping() {
        return this.skipTimeOptions.map((option, index) => ({
          value: index,
          label: `${option} ${this.$t('main.ad_form.seconds')}`
        }))
      },
      skipTimeIndex() {
        return this.skipTimeOptions.indexOf(Number(this.skipTime))
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    methods: {
      ...mapActions('adForm', ['setSkipTime']),
      changeSkipTime(indexTime) {
        this.setSkipTime(this.skipTimeOptions[indexTime])
      }
    }
  }
</script>

<style scoped>
  .skip-time-select {
    max-width: 215px;
  }
</style>
