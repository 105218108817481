<template>
  <v-expansion-panels :value="indexOpenPanel">
    <v-expansion-panel class="border-secondary">
      <v-expansion-panel-header class="secondary">
        <template #actions>
          <v-icon color="primary">
            mdi-chevron-down
          </v-icon>
        </template>

        <span class="d-flex align-center justify-space-between flex-wrap">
          {{ title }} {{ `#${creativeIndex + 1}` }}

          <multi-popunder
            v-if="adFormatTypeIsPopunder && creativeIndex === 0"
            :creative-index="creativeIndex"
          />
        </span>

        <div class="d-flex justify-end pr-3">
          <close-button v-if="removable" @click.native.stop="removeCreative(creative)" />
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <slot name="content" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import CloseButton from '@/components/CloseButton.vue'
  import MultiPopunder from '@/components/ads/PopunderForm/MultiPopunder.vue'

  export default {
    name: 'ExpansionPanelCreative',
    components: { CloseButton, MultiPopunder },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      },
      title: {
        type: String,
        default: ''
      },
      removable: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        indexOpenPanel: 0
      }
    },
    computed: {
      ...mapState('adForm', ['creatives', 'adFormatType']),
      ...mapGetters('adForm', ['getIsEditing']),
      adFormatTypeIsPopunder() {
        return this.adFormatType === adFormats.POPUNDER
      },
      creativesLength() {
        return this.creatives.length
      },
      creativeIsMoreOne() {
        return this.creativesLength > 1
      },
      creativeIsLast() {
        return this.creativesLength === this.creativeIndex + 1
      }
    },
    watch: {
      creativesLength: {
        immediate: true,
        handler() {
          this.openCreativePanel()
        }
      }
    },
    methods: {
      ...mapActions('adForm', ['removeCreative']),
      openCreativePanel() {
        if (this.getIsEditing) {
          if (this.creativeIsMoreOne) this.indexOpenPanel = 1
          if (this.creativeIsLast) this.indexOpenPanel = 0
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 49px !important;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }
  ::v-deep .v-expansion-panel::before {
    box-shadow: none !important;
  }
</style>
