<template>
  <div>
    <component
      :is="creativeComponent"
      v-for="(creative, index) in creatives"
      :key="creative.uuid"
      :ref="`creative#${creative.uuid}`"
      :creative-index="index"
      :removable="isCreativesRemoveAvailable"
      :creative="creative"
      class="my-2"
      @update-creative="updateCreative($event, index)"
    />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import PopunderCreative from '@/components/ads/PopunderForm/PopunderCreative.vue'
  import BannerCreative from '@/components/ads/BannerForm/BannerCreative.vue'
  import PushCreative from '@/components/ads/PushForm/PushCreative.vue'
  import InStreamCreative from '@/components/ads/InstreamForm/InStreamCreative.vue'
  import NativeAdCreative from '@/components/ads/NativeAdForm/NativeAdCreative.vue'
  import InterstitialCreative from '@/components/ads/InterstitialsForm/InterstitialCreative.vue'
  import IosCalendarCreative from '@/components/ads/IosCalendarForm/IosCalendarCreative.vue'
  import InPageAdCreative from '@/components/ads/InPageAdForm/InPageAdCreative.vue'

  export default {
    name: 'Creatives',
    components: {
      PopunderCreative,
      BannerCreative,
      PushCreative,
      InStreamCreative,
      NativeAdCreative,
      InterstitialCreative,
      IosCalendarCreative,
      InPageAdCreative
    },
    computed: {
      ...mapState('adForm', ['creatives', 'adFormatType']),
      isCreativesRemoveAvailable() {
        return this.creatives.length > 1
      },
      creativeComponent() {
        return {
          [adFormats.POPUNDER]: PopunderCreative,
          [adFormats.BANNER]: BannerCreative,
          [adFormats.WEB_PUSH]: PushCreative,
          [adFormats.IN_STREAM_AD]: InStreamCreative,
          [adFormats.NATIVE_AD]: NativeAdCreative,
          [adFormats.INTERSTITIAL]: InterstitialCreative,
          [adFormats.IOS_CALENDAR]: IosCalendarCreative,
          [adFormats.IN_PAGE_AD]: InPageAdCreative
        }[this.adFormatType]
      }
    },
    methods: {
      ...mapActions('adForm', ['setCreatives']),

      updateCreative(creative, index) {
        const creativesCopy = JSON.parse(JSON.stringify(this.creatives))
        creativesCopy[index] = creative
        this.setCreatives(creativesCopy)
      },

      checkFilesUpload() {
        if (!this.creatives.length) return true

        const uploadsRefs = this.creatives.reduce((acc, creative) => {
          const creativeRef = this.$refs[`creative#${creative.uuid}`][0]
          if (creativeRef) {
            acc.push(...Object.values(creativeRef.$refs))
          }
          return acc.filter((ref) => ref?.checkAllFilesIsUpload)
        }, [])

        if (!uploadsRefs.length) return true

        return uploadsRefs.every((upload) => upload.checkAllFilesIsUpload())
      }
    }
  }
</script>
