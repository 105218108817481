<template>
  <div>
    <ad-name-field />
    <ad-priority v-if="rotationIsRandom" />
    <ad-common-url-field ad-format-type="push" />
    <creatives ref="creatives" />
    <rotation-type-field v-if="!creativeIsSingle" class="my-6" />
    <ad-smart-threshold-field v-if="rotationTypeIsSmart && !creativeIsSingle" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AdNameField from '@/components/ads/AdNameField.vue'
  import RotationTypeField from '@/components/ads/RotationTypeField.vue'
  import AdCommonUrlField from '@/components/ads/AdCommonUrlField.vue'
  import Creatives from '@/components/ads/Creatives.vue'
  import AdPriority from '@/components/ads/AdPriority.vue'
  import AdSmartThresholdField from '@/components/ads/AdSmartThresholdField.vue'

  export default {
    name: 'PushForm',
    components: {
      AdPriority,
      AdSmartThresholdField,
      AdNameField,
      RotationTypeField,
      AdCommonUrlField,
      Creatives
    },
    computed: {
      ...mapGetters('adForm', ['creativeIsSingle', 'rotationTypeIsSmart', 'rotationIsRandom'])
    }
  }
</script>
