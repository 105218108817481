<template>
  <div>
    <ad-name-field />
    <ad-priority class="mb-6" />
    <creatives ref="creatives" />
  </div>
</template>

<script>
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdPriority from '@/components/ads/AdPriority.vue'
  import Creatives from '@/components/ads/Creatives.vue'

  export default {
    name: 'PopunderForm',
    components: {
      AdPriority,
      AdNameField,
      Creatives
    }
  }
</script>
