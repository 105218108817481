<template>
  <div class="windows-pro-push">
    <div class="windows-pro-push__container full-width">
      <div
        class="windows-pro-push__icon"
        :style="{
          backgroundImage: `url(${creative.icon.src})`
        }"
      />
      <div class="windows-pro-push__content">
        <div class="windows-pro-push__content-title">
          {{ creative.title }}
        </div>
        <div class="windows-pro-push__content-description">
          {{ creative.body || creative.description }}
        </div>
        <div class="windows-pro-push__content-site">
          {{ creative.url }}
        </div>
      </div>
      <div class="windows-pro-push__container-icons">
        <div class="windows-pro-push__container-icons-item">
          <svg class="sc-bwzfXH jgGGHw" color="hsl(0, 0%, 50%)">
            <use xlink:href="#icons_settings">
              <svg id="icons_settings" viewBox="0 0 24 24">
                <path
                  d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"
                />
              </svg>
            </use>
          </svg>
        </div>
        <div class="windows-pro-push__container-icons-item">
          <svg class="sc-bwzfXH jgGGHw" color="hsl(0, 0%, 50%)">
            <use xlink:href="#icons_close">
              <svg id="icons_close" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path
                  d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                />
              </svg>
            </use>
          </svg>
        </div>
      </div>
    </div>
    <div
      v-if="creative.image.src"
      class="windows-pro-push__image full-width"
      :style="{
        backgroundImage: `url(${creative.image.src})`
      }"
    />
    <v-row
      v-if="buttonsAreShown && creative.buttonOne && creative.buttonTwo"
      class="secondary full-width ma-0"
    >
      <v-col cols="6" class="text-truncate font-weight-medium">
        {{ creative.buttonOne }}
      </v-col>
      <v-col cols="6" class="text-truncate font-weight-medium">
        {{ creative.buttonTwo }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'WindowsProPush',
    props: {
      creative: {
        type: Object,
        required: true
      },
      buttonsAreShown: {
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .full-width {
    width: 100%;
  }
  .windows-pro-push {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 330px;
    max-width: 100%;

    &__container {
      flex-direction: row;
      justify-content: space-around;
      padding: 10px 5px;
      position: relative;
      background-color: var(--v-white-base);
      min-height: 70px;
      letter-spacing: 0.18px;
    }

    &__container,
    &__container-icons {
      display: flex;
    }

    &__container-icons-item {
      height: 12px;
      margin-left: 8px;
    }

    &__container-icons-item:first-child {
      margin-left: 0;
    }

    &__icon {
      width: 50px;
      height: 50px;
      margin-right: 5px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }

    &__content {
      display: flex;
      flex-direction: column;
      letter-spacing: 0.3px;
      width: 320px;
      max-width: calc(100% - 90px);
    }

    &__content-title {
      font-size: 12px;
      margin-bottom: 2px;
      font-weight: 500;
      line-height: 1;
    }

    &__content-description,
    &__content-title {
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--v-black-base);
    }

    &__content-description {
      font-size: 12px;
    }

    &__content-site {
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      color: var(--v-secondary-darken-base);
      letter-spacing: 0.3px;
    }

    &__image {
      height: 180px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
  }
  .jgGGHw {
    display: inline-flex;
    width: 12px;
    height: 12px;
    fill: var(--v-secondary-darken-base);
  }
</style>
