<template>
  <div>
    <ad-name-field />
    <ad-priority v-if="rotationIsRandom" />
    <ad-smart-threshold-field v-if="rotationTypeIsSmart && !creativeIsSingle" />
    <ad-common-url-field ad-format-type="inPage" />
    <creatives v-if="!inPageSkinGroupsAreLoading" ref="creatives" />
    <rotation-type-select v-if="!creativeIsSingle" class="my-6" />
    <ad-smart-threshold-field v-if="rotationTypeIsSmart && !creativeIsSingle" />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdSmartThresholdField from '@/components/ads/AdSmartThresholdField.vue'
  import Creatives from '@/components/ads/Creatives.vue'
  import AdCommonUrlField from '@/components/ads/AdCommonUrlField.vue'
  import RotationTypeSelect from '@/components/ads/RotationTypeField.vue'
  import AdPriority from '@/components/ads/AdPriority.vue'

  export default {
    name: 'InPageAdForm',
    components: {
      AdPriority,
      AdSmartThresholdField,
      AdNameField,
      RotationTypeSelect,
      Creatives,
      AdCommonUrlField
    },
    computed: {
      ...mapGetters('adForm', ['creativeIsSingle', 'rotationTypeIsSmart', 'rotationIsRandom']),
      ...mapState('collections', ['inPageSkinGroupsAreLoading'])
    },
    created() {
      this.fetchInPageSkinGroups()
    },
    methods: {
      ...mapActions('collections', ['fetchInPageSkinGroups'])
    }
  }
</script>
