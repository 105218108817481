<template>
  <div>
    <c-select
      :id="seleniumIds.SELENIUM_TEST_AD_FORM_ICON"
      :value="icon"
      :items="directLinkIcons"
      item-value="id"
      item-text="name"
      :label="$t('main.ad_form.select_icon')"
      :placeholder="$t('main.ad_form.select_icon')"
      label-bold
      item-slot
      hide-details="auto"
      class="mb-2"
      background-color="white"
      :error-messages="errors.icon"
      @change="changeIcon($event)"
    >
      <template #prepend-inner>
        <v-img v-if="selectedIconSrc" :src="selectedIconSrc" class="mt-1 mr-1" />
      </template>

      <template #item="{ item, on }">
        <v-list-item v-on="on">
          <v-img :src="item.src" class="mr-2" max-width="20" max-height="20" />
          {{ item.name }}
        </v-list-item>
      </template>
    </c-select>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'IconSelect',
    components: { CSelect },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('settings', ['directLinkIcons']),
      ...mapState('adForm', ['icon', 'errors']),
      selectedIconSrc() {
        const foundIcon = this.directLinkIcons.find((icon) => icon.id === this.icon)
        return foundIcon ? foundIcon.src : ''
      }
    },
    methods: {
      ...mapActions('adForm', ['setIcon', 'removeError']),
      changeIcon(iconId) {
        this.setIcon({ id: iconId })
        this.removeError('icon')
      }
    }
  }
</script>
