<template>
  <div>
    <div :class="{ 'set-priority': creativeIsSingle }">
      <div class="text-subtitle-2 black--text mb-3 mb-sm-2">
        {{ $t('main.ad_form.priority_all_urls') }}
      </div>
      <c-btn
        outlined
        :disabled="creativeIsSingle"
        color="primary"
        :block="isMobile"
        large
        :label="$t('main.ad_form.reset')"
        @click="isShown = true"
      />
    </div>

    <v-dialog v-model="isShown" max-width="800">
      <v-card class="pb-5 pb-sm-8">
        <v-card-title class="d-block global-break-normal pb-5">
          <close-button right absolute @click="isShown = false" />
          <div class="d-flex justify-center font-weight-medium black--text pt-8 pr-5">
            {{ $t('main.ad_form.priority_default') }}
          </div>
        </v-card-title>
        <v-card-text class="px-5 px-sm-12 pb-0">
          <v-alert text color="info" icon="mdi-information-outline" class="mb-0">
            {{ $t('main.ad_form.priority_info') }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="justify-center flex-column flex-sm-row pb-2 pt-6">
          <c-btn
            width="180"
            color="primary"
            :block="isMobile"
            class="mt-3"
            large
            :label="$t('main.ad_form.reset')"
            @click="updateCreatives()"
          />

          <c-btn
            width="180"
            outlined
            color="black"
            :block="isMobile"
            large
            class="mt-3 ml-0 ml-sm-2"
            :label="$t('main.ad_form.no_set')"
            @click="isShown = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CloseButton from '@/components/CloseButton.vue'

  export default {
    name: 'AdPriority',
    components: {
      CloseButton,
      CBtn
    },
    data() {
      return {
        isShown: false
      }
    },
    computed: {
      ...mapState('adForm', ['creatives', 'defaultPriority']),
      ...mapGetters('adForm', ['creativeIsSingle']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    methods: {
      ...mapActions('adForm', ['setCreatives']),
      updateCreatives() {
        const creativesCopy = this.creatives.map((creative) => ({
          ...creative,
          priority: this.defaultPriority
        }))
        this.setCreatives(creativesCopy)
        this.isShown = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .set-priority {
    opacity: 0.4;
    & .v-btn {
      color: var(--v-primary-base) !important;
      border-color: var(--v-primary-base);
      opacity: 0.4;
    }
  }
</style>
