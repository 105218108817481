<template>
  <svg class="chrome-icon" color="hsl(0, 0%, 50%)">
    <use href="#icons_chrome-colored">
      <svg id="icons_chrome-colored" viewBox="0 0 24 24">
        <g fill="none">
          <path d="M0 0h24v24H0z" />
          <path
            d="M2.898 4.18C8.476-2.311 18.82-1.03 22.686 6.555h-9.293c-1.674 0-2.755-.038-3.926.579-1.376.724-2.414 2.067-2.777 3.644L2.898 4.181z"
            :fill="currentColor ? 'currentColor' : '#EA4335'"
          />
          <path
            d="M8.008 12c0 2.2 1.789 3.99 3.988 3.99A3.994 3.994 0 0 0 15.984 12c0-2.2-1.789-3.99-3.988-3.99-2.2 0-3.988 1.79-3.988 3.99z"
            :fill="currentColor ? 'currentColor' : '#4285F4'"
          />
          <path
            d="M13.544 17.223c-2.239.665-4.858-.073-6.293-2.55-1.096-1.89-3.99-6.932-5.305-9.224-4.607 7.06-.636 16.683 7.727 18.325l3.87-6.551z"
            :fill="currentColor ? 'currentColor' : '#34A853'"
          />
          <path
            d="M15.7 8.01a5.464 5.464 0 0 1 1.008 6.718c-.951 1.64-3.988 6.766-5.46 9.248 8.617.531 14.9-7.914 12.062-15.967H15.7z"
            :fill="currentColor ? 'currentColor' : '#FBBC05'"
          />
        </g>
      </svg>
    </use>
  </svg>
</template>

<script>
  export default {
    name: 'ChromeIcon',
    props: {
      currentColor: Boolean
    }
  }
</script>

<style lang="scss" scoped>
  .chrome-icon {
    display: inline-flex;
    width: 32px;
    height: 32px;
  }
</style>
