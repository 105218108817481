<template>
  <div class="preview rounded">
    <iframe
      v-if="frameUrlIsValid"
      ref="frame"
      width="100%"
      height="100%"
      :src="frameUrl"
      frameborder="0"
    />
    <div v-else class="preview__empty">
      <div class="mt-3">
        <div class="v-messages__message">
          {{ $t('main.ad_form.preview_url_error') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InterstitialsPreviewFrame',
    props: {
      frameUrl: {
        type: String,
        required: true
      }
    },
    computed: {
      frameUrlIsValid() {
        const urlReg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
        return urlReg.test(this.frameUrl)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .preview {
    height: 100%;
    width: 100%;

    &__empty {
      align-self: center;
      text-align: center;
      color: rgba(#000, 0.34);
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .position-sticky-preview {
    position: sticky;
    bottom: 0;
  }
</style>
