<template>
  <div class="apple-push">
    <div class="apple-push__push apple-push__push_animated">
      <div class="apple-push__push-browser pl-1">
        <chrome-icon />
      </div>

      <div class="apple-push__push-content">
        <div class="apple-push__push-content-title">
          {{ creative.title }}
        </div>
        <div class="apple-push__push-content-site">
          {{ creative.url }}
        </div>
        <div class="apple-push__push-content-description">
          {{ creative.body || creative.description }}
        </div>
      </div>

      <div
        class="apple-push__push-icon"
        :style="{
          backgroundImage: `url(${creative.icon.src})`
        }"
      />
    </div>
  </div>
</template>

<script>
  import ChromeIcon from './ChromeIcon.vue'

  export default {
    name: 'ApplePush',
    components: {
      ChromeIcon
    },
    props: {
      creative: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .apple-push {
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
    width: 330px;
    max-width: 100%;

    &__push {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 5px;
      border-radius: 6px;
      box-shadow: inset 0 0 0 1px var(--v-secondary-lighten-base);
      background-color: var(--v-secondary-darken-base);
      transform: translate(370px);
    }

    &__push_animated {
      transform: translate(0);
      transition: transform 0.4s ease;
    }

    &__push-content {
      width: 320px;
      max-width: calc(100% - 88px);
      font-size: 12px;
      letter-spacing: 0.3px;
      color: var(--v-white-base);
      padding-left: 13px;
      padding-right: 13px;
    }

    &__push-content-title {
      font-weight: 700;
      font-size: 12px;
    }

    &__push-content-site,
    &__push-content-title {
      margin-bottom: 2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__push-content-site {
      font-weight: 500;
    }

    &__push-content-description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__push-icon {
      width: 43px;
      flex-shrink: 0;
      height: 43px;
      border-radius: 3px;
      background-size: cover;
      background-position: center center;
    }
  }
</style>
