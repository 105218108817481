<template>
  <div>
    <ad-name-field />

    <ads-device-type
      class="mb-3"
      :title="$t('main.ad_form.size')"
      :is-disabled="!!presetedSize"
      :interstitials-sizes="interstitialsSizes"
      @change="setInterstitialsSize($event)"
    />

    <ad-type-field class="mb-3" :title="$t('main.ad_form.ad_type')" :ad-types="interstitialAdTypes" />

    <div v-if="creativesIsShown">
      <ad-common-url-field v-if="!adTypeIsLanding" ad-format-type="interstitial" />
      <creatives ref="creatives" />
    </div>
    <ad-self-code
      v-if="adTypeIsSelfcode"
      :self-code-please-contact="$t('main.ad_form.self_code.please_contact')"
      :self-code-for-the-further="$t('main.ad_form.self_code.for_the_further')"
      :permission="createSelfCodeInterstitialsPermission"
      class="mb-4"
    />
    <rotation-type-field v-if="!adTypeIsSelfcode && !creativeIsSingle" class="my-6" />
    <ad-smart-threshold-field v-if="thresholdFieldIsShow" />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { adTypes } from '@clickadilla/components/constants/ad-types.js'
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdTypeField from '@/components/ads/AdTypeField.vue'
  import AdsDeviceType from '@/components/ads/InterstitialsForm/AdsDeviceType.vue'
  import AdSmartThresholdField from '@/components/ads/AdSmartThresholdField.vue'
  import AdSelfCode from '@/components/ads/AdSelfCode.vue'
  import permissions from '@/store/permissions.js'
  import AdCommonUrlField from '@/components/ads/AdCommonUrlField.vue'
  import Creatives from '@/components/ads/Creatives.vue'
  import RotationTypeField from '@/components/ads/RotationTypeField.vue'

  export default {
    name: 'InterstitialsForm',
    components: {
      AdSmartThresholdField,
      AdTypeField,
      AdNameField,
      AdsDeviceType,
      RotationTypeField,
      AdSelfCode,
      AdCommonUrlField,
      Creatives
    },
    props: {
      presetedSize: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapState('adForm', ['size', 'type']),
      ...mapGetters('settings', ['interstitialsSizes', 'interstitialAdTypes']),
      ...mapGetters('adForm', [
        'creativeIsSingle',
        'rotationTypeIsSmart',
        'adTypeIsImage',
        'adTypeIsSelfcode',
        'adTypeIsLanding'
      ]),
      createSelfCodeInterstitialsPermission() {
        return permissions.CREATE_SELF_CODE_INTERSTITIALS
      },
      creativesIsShown() {
        const availableTypes = [adTypes.LANDING, adTypes.IMAGE]
        return availableTypes.includes(this.type) && !!this.size
      },
      thresholdFieldIsShow() {
        return this.rotationTypeIsSmart && !this.creativeIsSingle && !this.adTypeIsSelfcode
      }
    },
    methods: {
      ...mapActions('adForm', ['setSize', 'removeError']),
      setInterstitialsSize(indexSize) {
        const size = this.interstitialsSizes[indexSize]
        this.setSize(size)
      }
    }
  }
</script>
