<template>
  <expansion-panel-creative
    :title="$t('main.ad_form.creative')"
    :removable="removable"
    :creative="creative"
    :creative-index="creativeIndex"
  >
    <template #content>
      <v-card-text class="pa-6">
        <v-container>
          <v-row>
            <v-col cols="12" md="9" lg="10" class="pa-0 pr-md-2">
              <ad-url-field
                :selenium-id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_URL, creativeIndex)"
                :url="creative.url"
                :creative-index="creativeIndex"
                :placeholder="$t('main.ad_form.url_placeholder')"
                @input="setCreative($event, 'url')"
                @focus="removeError([`creatives.${creativeIndex}.url`, `creatives.url`])"
              />
            </v-col>

            <v-col cols="12" md="3" lg="2" class="pa-0">
              <creative-priority
                :creative-index="creativeIndex"
                :creative="creative"
                @input="setCreative($event, 'priority')"
                @focus="removeError(`creatives.${creativeIndex}.priority`)"
              />
            </v-col>
          </v-row>
        </v-container>
        <ad-formats-url-tokens
          v-if="creativeTokensLinks.length"
          :value="creative.url"
          :tokens="creativeTokensLinks"
          class="mb-6"
          @input="setCreative($event, 'url')"
        />
        <c-btn
          v-if="isFinalElement && !getIsEditing"
          :id="seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_ADD"
          color="primary"
          outlined
          depressed
          large
          class="py-3 px-4"
          :block="isMobile"
          :label="$t('main.ad_form.added_url')"
          :icon-props="{
            icon: '$plus',
            size: '14'
          }"
          @click="addCreative()"
        />
      </v-card-text>
    </template>
  </expansion-panel-creative>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import AdFormatsUrlTokens from '@/components/ads/AdFormatsUrlTokens/Index.vue'
  import CreativePriority from '@/components/ads/CreativePriority.vue'
  import ExpansionPanelCreative from '@/components/ads/CreativeLayout/ExpansionPanelCreative.vue'
  import AdUrlField from '@/components/ads/AdUrlField.vue'

  export default {
    name: 'PopunderCreative',
    components: {
      AdFormatsUrlTokens,
      CreativePriority,
      CBtn,
      ExpansionPanelCreative,
      AdUrlField
    },
    props: {
      creativeIndex: {
        type: Number,
        required: true
      },
      creative: {
        type: Object,
        required: true
      },
      removable: Boolean
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapGetters('adForm', ['getIsEditing', 'creativeTokensLinks']),
      ...mapState('adForm', ['errors', 'creatives']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isFinalElement() {
        return this.creativeIndex === this.creatives.length - 1
      }
    },
    methods: {
      ...mapActions('adForm', ['removeError', 'addCreative']),
      setCreative(value, name) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        creativeCopy[name] = value
        this.$emit('update-creative', creativeCopy)
      },
      seleniumDynamicField
    }
  }
</script>
