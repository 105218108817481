<template>
  <div class="windows-push">
    <windows-home-push
      v-show="platformType === 'win10home'"
      :buttons-are-shown="buttonsAreShown"
      :creative="creative"
    />
    <windows-pro-push
      v-show="platformType === 'win7810pro'"
      :buttons-are-shown="buttonsAreShown"
      :creative="creative"
    />
  </div>
</template>

<script>
  import WindowsHomePush from './WindowsHomePush.vue'
  import WindowsProPush from './WindowsProPush.vue'

  export default {
    name: 'WindowsPush',
    components: {
      WindowsHomePush,
      WindowsProPush
    },
    props: {
      platformType: {
        type: String,
        required: true
      },
      creative: {
        type: Object,
        required: true
      },
      buttonsAreShown: {
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .windows-push {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2px;
  }
</style>
