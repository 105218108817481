<template>
  <div>
    <ad-name-field />

    <ad-common-url-field ad-format-type="iosCalendar" />
    <creatives ref="creatives" />
  </div>
</template>

<script>
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdCommonUrlField from '@/components/ads/AdCommonUrlField.vue'
  import Creatives from '@/components/ads/Creatives.vue'

  export default {
    name: 'IosCalendarForm',
    components: {
      AdCommonUrlField,
      AdNameField,
      Creatives
    }
  }
</script>
