<template>
  <div class="android-push">
    <div class="android-push__push">
      <div class="android-push__push-header">
        <div class="android-push__push-header-browser">
          <chrome-icon class="chrome-icon" :current="true" />

          <div class="android-push__push-header-browser-title">
            Chrome
          </div>
        </div>
        <div class="android-push__push-header-site">
          {{ creative.url }}
        </div>
        <div class="android-push__push-header-time">
          <span>Now</span>
        </div>
      </div>

      <div class="android-push__push-container">
        <div class="android-push__push-main-content">
          <div class="android-push__push-title">
            {{ creative.title }}
          </div>
          <div class="android-push__push-description">
            {{ creative.body || creative.description }}
          </div>
        </div>

        <div
          class="android-push__push-icon"
          :style="{
            backgroundImage: `url(${creative.icon.src})`
          }"
        />

        <div
          v-show="platformType === 'expanded' && creative.image.src"
          class="android-push__push-image"
          :style="{
            backgroundImage: `url(${creative.image.src})`
          }"
        />
      </div>
      <v-row v-if="buttonsAreShown && platformType === 'expanded'" class="d-flex mt-0">
        <v-col cols="4" class="text-truncate font-weight-medium">
          {{ creative.buttonOne }}
        </v-col>
        <v-col cols="4" class="text-truncate font-weight-medium">
          {{ creative.buttonTwo }}
        </v-col>
        <v-col cols="4" class="text-truncate font-weight-medium">
          {{ $t('main.ad_form.settings') }}
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import ChromeIcon from './ChromeIcon.vue'

  export default {
    name: 'AndroidPush',
    components: {
      ChromeIcon
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      platformType: {
        type: String,
        required: true
      },
      buttonsAreShown: {
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .android-push {
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
    width: 330px;
    max-width: 100%;

    &__push {
      width: 100%;
      min-height: 70px;
      background: var(--v-white-base);
      padding: 10px;
      letter-spacing: 0.18px;
      border-top: 1px solid var(--v-secondary-base);
      border-radius: 5px;
    }
    &__push-header {
      max-width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      font-size: 12px;
      color: var(--v-black-base);
      margin-bottom: 5px;
    }

    &__push-header,
    &__push-header-browser {
      display: flex;
      line-height: 1;
      align-items: center;
    }

    &__push-header-browser {
      margin-right: 6px;
    }

    &__push-header-browser-title {
      padding-left: 6px;
    }

    &__push-header-site {
      display: flex;
      margin-right: 6px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-direction: row;
      align-items: center;
    }

    &__push-header-site:before {
      content: '';
      display: block;
      min-width: 2px;
      height: 2px;
      background: var(--v-black-base);
      margin-right: 6px;
      border-radius: 2px;
    }

    &__push-header-time {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__push-header-time:before {
      content: '';
      display: block;
      min-width: 2px;
      height: 2px;
      background: var(--v-black-base);
      margin-right: 6px;
      border-radius: 2px;
    }

    &__push-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__push-main-content {
      width: 330px;
      max-width: calc(100% - 50px);
      display: flex;
    }

    &__push-main-content {
      min-height: 34px;
      justify-content: center;
      flex-direction: column;
      font-size: 12px;
    }

    &__push-title {
      margin-bottom: 1px;
      font-weight: 500;
    }

    &__push-description,
    &__push-title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__push-description {
      color: var(--v-secondary-base);
    }

    &__push-icon {
      width: 43px;
      flex-shrink: 0;
      height: 43px;
      border-radius: 3px;
      background-size: cover;
      background-position: center center;
    }

    &__push-image {
      width: 100%;
      min-height: 180px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      margin: 5px 0 0;
    }

    &__image-notice {
      padding-top: 20px;
      font-size: 12px;
      letter-spacing: 0.3px;
      color: var(--v-secondary-base);
    }

    .chrome-icon {
      width: 12px;
      height: 12px;
    }
  }
</style>
