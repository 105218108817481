var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',_vm._l((_vm.tokens),function(token){return _c('v-col',{key:token,staticClass:"pa-1",attrs:{"cols":"6","sm":"auto"}},[_c('v-chip',{class:[
          'd-block text-center',
          {
            'text-body-2': _vm.isMobile
          }
        ],attrs:{"large":_vm.isMobile,"label":"","dark":_vm.selectedTokens.includes(token),"color":_vm.selectedTokens.includes(token) ? 'black' : _vm.chipColor},on:{"click":function($event){return _vm.toggleToken(token)}}},[_c('span',{class:[
            'text-truncate text-lowercase',
            {
              'black--text': !_vm.selectedTokens.includes(token)
            }
          ]},[_vm._v(" "+_vm._s(token)+" ")])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }