<template>
  <div :class="[{ 'position-sticky-preview': !previewIsAnchor }]">
    <interstitials-preview-dialog
      v-if="previewIsFullscreen"
      :preview-is-fullscreen="previewIsFullscreen"
      :frame-url="frameUrl"
      @close-dialog="previewIsFullscreen = false"
    />

    <v-card v-show="!previewIsFullscreen" class="black pa-3 pa-sm-8 pt-sm-0" elevation="0">
      <v-card-title class="d-flex align-center justify-center px-0 pt-1 pb-2 py-sm-4">
        <c-btn
          :icon-props="{
            size: 20,
            color: previewColorFullscreen,
            icon: '$full-screen'
          }"
          class="mx-2"
          icon
          @click="previewIsFullscreen = true"
        />

        <c-btn
          :icon-props="{
            size: 20,
            color: previewColorAnchor,
            icon: '$anchor'
          }"
          class="mx-2"
          icon
          @click="previewIsAnchor = !previewIsAnchor"
        />
      </v-card-title>
      <interstitials-preview-frame :frame-url="frameUrl" />
    </v-card>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import InterstitialsPreviewDialog from '@/components/ads/InterstitialsForm/InterstitialsPreviewDialog.vue'
  import InterstitialsPreviewFrame from '@/components/ads/InterstitialsForm/InterstitialsPreviewFrame.vue'

  export default {
    name: 'InterstitialsPreview',
    components: {
      CBtn,
      InterstitialsPreviewDialog,
      InterstitialsPreviewFrame
    },
    props: {
      frameUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        previewIsFullscreen: false,
        previewIsAnchor: false
      }
    },
    computed: {
      previewColorFullscreen() {
        return this.previewIsFullscreen ? 'primary' : 'white'
      },
      previewColorAnchor() {
        return this.previewIsAnchor ? 'primary' : 'white'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .preview {
    height: 344px;
    background: var(--v-secondary-lighten-base);
  }
  .position-sticky-preview {
    position: sticky;
    bottom: 0;
  }
</style>
