<template>
  <div>
    <ad-help-video-dialog
      :dialog-is-shown="helpDialogIsShown"
      :help-video-url="helpVideoUrl"
      @close-dialog="helpDialogIsShown = false"
    />

    <a
      :class="[
        'primary--text mt-5 mt-sm-0',
        {
          'text-body-2': isMobile
        }
      ]"
      @click="helpDialogIsShown = !helpDialogIsShown"
    >
      <v-icon color="primary"> mdi-youtube-tv </v-icon>

      <span class="text-subtitle-2 pl-2">
        {{ $t('main.ad_form.what_is', { adName }) }}
      </span>
    </a>
  </div>
</template>

<script>
  import AdHelpVideoDialog from '@/components/ads/WhatIsPageAds/AdHelpVideoDialog.vue'

  export default {
    name: 'WhatIsPageAds',
    components: {
      AdHelpVideoDialog
    },
    props: {
      adName: {
        type: String,
        required: true
      },
      helpVideoUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        helpDialogIsShown: false
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    }
  }
</script>
