<template>
  <div>
    <ad-name-field />

    <c-text-field
      :id="seleniumIds.SELENIUM_TEST_AD_FORM_TITLE"
      :value="title"
      :error-messages="errors.title"
      :label="$t('main.ad_form.title')"
      label-bold
      :placeholder="$t('main.ad_form.enter_the_title')"
      :hint="`${$t('main.ad_form.max')}: ${maxSymbol.title} ${$t('main.ad_form.characters')}`"
      hide-details="auto"
      :counter="maxSymbol.title"
      :maxlength="maxSymbol.title"
      background-color="white"
      class="mt-4"
      @input="setTitle($event)"
      @focus="removeError('title')"
    />

    <ad-url-field
      :selenium-id="seleniumIds.SELENIUM_TEST_AD_FORM_URL"
      :url="url"
      :placeholder="$t('main.ad_form.url_placeholder')"
      background-color="white"
      is-not-creative
      @input="setUrl($event)"
      @focus="removeError('url')"
    />

    <ad-formats-url-tokens
      v-if="creativeTokensLinks.length"
      :value="url"
      :tokens="creativeTokensLinks"
      class="mb-4"
      :is-light="false"
      @input="setUrl($event)"
    />

    <icon-select />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import AdFormatsUrlTokens from '@/components/ads/AdFormatsUrlTokens/Index.vue'
  import IconSelect from './IconSelect.vue'
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdUrlField from '@/components/ads/AdUrlField.vue'

  export default {
    name: 'DirectLinkForm',
    components: {
      AdNameField,
      IconSelect,
      AdFormatsUrlTokens,
      CTextField,
      AdUrlField
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['title', 'url', 'errors']),
      ...mapState('settings', {
        maxSymbol: (state) => state.adsDataRequirements.directLink
      }),
      ...mapGetters('adForm', ['creativeTokensLinks'])

    },
    methods: {
      ...mapActions('adForm', ['setTitle', 'setUrl', 'removeError'])
    }
  }
</script>
