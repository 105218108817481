<template>
  <div>
    <div class="d-flex align-center mb-2">
      <span class="text-subtitle-2">{{ $t('main.ad_form.buttons_settings') }}</span>
      <span
        class="text-caption text-uppercase font-weight-bold primary white--text rounded ml-2 py-1 px-2"
      >
        {{ $t('main.ad_form.new') }}
      </span>
    </div>
    <v-sheet class="secondary-lighten border-secondary rounded pa-6">
      <v-row>
        <v-col cols="12" sm="6">
          <c-text-field
            :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_BUTTON_1, creativeIndex)"
            :value="buttons.buttonOne"
            label-bold
            background-color="white"
            :placeholder="$t('main.ad_form.enter_button_text')"
            :error-messages="
              errors[`creatives.${creativeIndex}.button_one`] || errors[`creatives.button_one`]
            "
            hide-details="auto"
            @input="setButtonLabel('buttonOne', $event)"
          >
            <template #label>
              <span class="mb-1">{{ $t('main.ad_form.button') }} 1</span>
            </template>
          </c-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <c-text-field
            :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_BUTTON_2, creativeIndex)"
            :value="buttons.buttonTwo"
            label-bold
            background-color="white"
            :placeholder="$t('main.ad_form.enter_button_text')"
            :error-messages="
              errors[`creatives.${creativeIndex}.button_two`] || errors[`creatives.button_two`]
            "
            hide-details="auto"
            @input="setButtonLabel('buttonTwo', $event)"
          >
            <template #label>
              <span class="mb-1">{{ $t('main.ad_form.button') }} 2</span>
            </template>
          </c-text-field>
        </v-col>
      </v-row>
      <v-alert text icon="mdi-information-outline" color="info" class="mb-0 mt-3">
        {{ $t('main.ad_form.add_buttons') }}
      </v-alert>
    </v-sheet>
  </div>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapState } from 'vuex'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'

  export default {
    name: 'ButtonsSettings',
    components: {
      CTextField
    },
    props: {
      buttons: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['errors'])
    },
    methods: {
      seleniumDynamicField,
      setButtonLabel(name, value) {
        this.$emit('set-creative', { name, value })
      }
    }
  }
</script>
