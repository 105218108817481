<template>
  <v-dialog
    :value="dialogIsShown"
    max-width="1000"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
  >
    <v-card height="60vh">
      <iframe
        v-if="dialogIsShown"
        ref="video"
        width="100%"
        height="100%"
        :src="computedHelpVideoUrl"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'AdHelpVideoDialog',
    props: {
      dialogIsShown: {
        type: Boolean,
        default: false
      },
      helpVideoUrl: {
        type: String,
        required: true
      }
    },
    computed: {
      computedHelpVideoUrl() {
        return `${this.helpVideoUrl}?autoplay=1`
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog')
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-dialog {
    overflow-y: hidden;
  }
</style>
