<template>
  <div>
    <span class="text-subtitle-2 black--text ma-0 pa-0">
      {{ $t('main.ad_form.motion_banner.colors_settings') }}
    </span>
    <v-card class="border-secondary rounded-lg px-6 py-5 mt-1" elevation="0">
      <color-picker
        :value="motionBannerParams.buttonTextColor"
        autocomplete="off"
        :error-messages="errors.buttonTextColor"
        class="mb-2"
        :label="$t('main.ad_form.motion_banner.button_text_color')"
        @input="setColorSettings({ value: $event, name: 'buttonTextColor' })"
      />
      <color-picker
        :value="motionBannerParams.faderColor"
        autocomplete="off"
        class="mb-2"
        :error-messages="errors.faderColor"
        :label="$t('main.ad_form.motion_banner.fader_color')"
        @input="setColorSettings({ value: $event, name: 'faderColor' })"
      />

      <color-picker
        :value="motionBannerParams.textColor"
        autocomplete="off"
        class="mb-2"
        :error-messages="errors.textColor"
        :label="$t('main.ad_form.motion_banner.text_color')"
        @input="setColorSettings({ value: $event, name: 'textColor' })"
      />

      <color-picker
        :value="motionBannerParams.buttonColor"
        autocomplete="off"
        class="mb-2"
        :error-messages="errors.buttonColor"
        :label="$t('main.ad_form.motion_banner.button_color')"
        @input="setColorSettings({ value: $event, name: 'buttonColor' })"
      />
      <color-picker
        :value="motionBannerParams.buttonHoverColor"
        autocomplete="off"
        class="mb-2"
        :error-messages="errors.buttonHoverColor"
        :label="$t('main.ad_form.motion_banner.button_hover_color')"
        @input="setColorSettings({ value: $event, name: 'buttonHoverColor' })"
      />

      <color-picker
        :value="motionBannerParams.buttonTextHoverColor"
        autocomplete="off"
        class="mb-2"
        :error-messages="errors.buttonTextHoverColor"
        :label="$t('main.ad_form.motion_banner.button_text_hover_color')"
        @input="setColorSettings({ value: $event, name: 'buttonTextHoverColor' })"
      />
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import ColorPicker from '@/components/ColorPicker.vue'

  export default {
    name: 'ColorSettings',
    components: { ColorPicker },
    props: {
      motionBannerParams: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapState('adForm', ['errors'])
    },
    methods: {
      ...mapActions('adForm', ['setMotionBannerParams']),
      setColorSettings({ value, name }) {
        const motionBannerParamsCopy = JSON.parse(JSON.stringify(this.motionBannerParams))
        motionBannerParamsCopy[name] = value

        this.setMotionBannerParams({
          creativeIndex: this.creativeIndex,
          motionBannerParams: motionBannerParamsCopy
        })
      }
    }
  }
</script>
