<template>
  <v-card elevation="0" class="black pt-3 pb-5 px-4 px-md-10">
    <div class="d-flex justify-end">
      <c-info-btn is-tooltip :text="$t('main.ad_form.graphic_creatives_info')" class="mr-n2 mr-md-n7" />
    </div>
    <v-card-title class="white--text justify-center text-subtitle-2 text-uppercase pa-0">
      {{ $t('main.ad_form.preview') }}
    </v-card-title>
    <v-card-text class="pa-0 mt-1">
      <v-expand-transition>
        <div v-show="!creative.inPageSkinGroup.id">
          <v-alert color="info">
            <div class="d-flex align-center white--text text-caption">
              <v-icon size="16" color="white" class="mr-4">
                $warning-outlined
              </v-icon>
              {{ $t('main.ad_form.alert_creative') }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-container fluid class="pt-0">
        <v-row v-if="inPageSkinGroups.length" no-gutters class="mx-n4">
          <v-col v-for="inPageSkinGroup in inPageSkinGroups" :key="inPageSkinGroup.id" class="pa-1">
            <c-btn
              :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_SKIN_GROUP, inPageSkinGroup.id)"
              block
              depressed
              outlined
              height="62"
              :color="creative.inPageSkinGroup.id === inPageSkinGroup.id ? 'primary' : 'secondary-darken'"
              @click="changeSkinGroup(inPageSkinGroup)"
            >
              <div class="d-flex flex-column justify-center">
                <div>
                  <v-icon size="16" :color="creative.inPageSkinGroup.id === inPageSkinGroup.id ? 'primary' : 'secondary'">
                    {{ getSkinGroupIcon(inPageSkinGroup.name) }}
                  </v-icon>
                </div>
                <div class="white--text mt-2">
                  {{ inPageSkinGroup.name }}
                </div>
              </div>
            </c-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text v-if="defaultInPageSkin || selectedSkinGroup && selectedSkinGroup.inPageSkins.length" class="preview-container pa-0">
      <v-overlay absolute :value="!creative.icon?.src" opacity="0.90" class="text-center">
        <div class="overlay-text text-caption">
          {{ $t('main.ad_form.upload_icon') }}
        </div>
      </v-overlay>
      <div v-if="adNetwork.settings.inPagePreviewTagId && !isMobile" class="rounded overflow-hidden">
        <div class="actions-container d-flex justify-end">
          <c-btn
            icon
            :icon-props="{
              size: 20,
              color: 'primary',
              icon: '$update'
            }"
            @click="updatePreview()"
          />
        </div>
        <in-page-preview
          :key="iframeKey"
          :settings="previewParams"
          :tag-id="adNetwork.settings.inPagePreviewTagId"
        />
      </div>
      <v-container v-if="selectedSkinGroup" fluid class="pb-0 pt-2">
        <v-row no-gutters class="mx-n4">
          <v-col v-for="skin in selectedSkinGroup.inPageSkins" :key="skin.id" class="pa-1" cols="auto">
            <c-btn
              :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_SKIN, skin.id)"
              depressed
              :color="creative.inPageSkin.id === skin.id ? 'primary' : 'secondary'"
              @click="changeSkin(skin)"
            >
              <span :class="[creative.inPageSkin.id === skin.id ? 'white--text' : 'black--text']">{{ skin.name }}</span>
            </c-btn>
          </v-col>
        </v-row>
      </v-container>
      <c-btn
        v-if="isMobile"
        depressed
        block
        class="mt-4"
        color="primary"
        :label="$t('main.ad_form.show_preview')"
        @click="previewModalIsShown = true"
      />
      <v-dialog :value="previewModalIsShown" @input="previewModalIsShown = false">
        <v-card>
          <in-page-preview
            v-if="adNetwork.settings.inPagePreviewTagId"
            :key="iframeKey"
            :settings="previewParams"
            :tag-id="adNetwork.settings.inPagePreviewTagId"
          />
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import CInfoBtn from '@clickadilla/components/ui/CInfoBtn.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import InPagePreview from '@/components/ads/InPagePreview.vue'
  import skinGroups from '@/types/skin-groups.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  const {
    IN_PAGE_SELECTED_SKIN,
    IN_PAGE_SELECTED_SKIN_GROUP
  } = GTM_EVENTS

  export default {
    name: 'InPageGraphicCreatives',
    components: { InPagePreview, CInfoBtn, CBtn },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: [Number, String],
        required: true
      }
    },
    data() {
      return {
        seleniumIds,
        iframeKey: 1,
        previewModalIsShown: false
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      ...mapGetters('collections', ['inPageSkinGroups', 'defaultInPageSkin']),
      selectedSkinGroup() {
        return this.inPageSkinGroups.find(({ id }) => id === this.creative.inPageSkinGroup.id)
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      previewParams() {
        return {
          description: this.creative.description ?? '',
          tdsId: this.creative.inPageSkin.tdsId || this.defaultInPageSkin?.tdsId,
          title: this.creative.title ?? '',
          icon: this.creative.icon?.src ?? '',
          image: this.creative.image?.src ?? ''
        }
      }
    },
    watch: {
      previewParams() {
        this.updatePreview()
      }
    },
    methods: {
      ...mapActions('adForm', ['removeError']),
      setCreative(value, name) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        creativeCopy[name] = value
        this.$emit('update-creative', creativeCopy)
      },
      changeSkinGroup(inPageSkinGroup) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        if (inPageSkinGroup.id === this.creative.inPageSkinGroup.id) {
          creativeCopy.inPageSkinGroup = {}
          creativeCopy.inPageSkin = {}
        } else {
          creativeCopy.inPageSkinGroup = inPageSkinGroup
          creativeCopy.inPageSkin = inPageSkinGroup.inPageSkins[0] ?? {}
        }
        this.$emit('update-creative', creativeCopy)

        gtmPush({ event: IN_PAGE_SELECTED_SKIN_GROUP })
      },
      changeSkin(skin) {
        if (skin.id === this.creative.inPageSkin.id) return

        this.setCreative(skin, 'inPageSkin')
        gtmPush({ event: IN_PAGE_SELECTED_SKIN })
      },
      updatePreview() {
        this.iframeKey += 1
      },
      getSkinGroupIcon(skinGroupName) {
        return {
          [skinGroups.STANDARD]: '$check',
          [skinGroups.DATING]: '$heart',
          [skinGroups.UTILITY]: '$all',
          [skinGroups.GAMBLING]: '$gambling',
          [skinGroups.BETTING]: '$spades'
        }[skinGroupName.toLowerCase()] ?? '$check'
      },
      seleniumDynamicField
    }
  }
</script>

<style scoped lang="scss">
  .preview-container {
    position: relative;
  }
  .overlay-text {
    max-width:400px
  }
  .actions-container {
    background-color: rgb(245 245 245 / 0.05);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 95%;
      background-color: var(--v-black-base);
    }
  }
</style>
