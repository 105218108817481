<template>
  <div>
    <ad-name-field />
    <c-select
      :id="seleniumIds.SELENIUM_TEST_AD_FORM_SIZE"
      :value="size"
      :items="getBannersSizes"
      :disabled="!!presetedSize || getIsEditing || isDuplicate"
      :error-messages="errors.size"
      :label="$t('main.ad_form.select_banner_size')"
      :placeholder="$t('main.ad_form.select_banner_size')"
      label-bold
      background-color="white"
      clearable
      @change="setSize($event)"
      @focus="removeError('size')"
    />

    <ad-type-field
      v-if="bannerAdTypes.length"
      :title="$t('main.ad_form.ad_type')"
      :ad-types="bannerAdTypes"
      class="mb-3"
    />

    <div v-if="creativesIsShown">
      <ad-common-url-field v-if="adTypeIsImage" ad-format-type="banner" />
      <creatives ref="creatives" />
    </div>

    <ad-self-code
      v-if="adTypeIsSelfcode"
      :self-code-please-contact="$t('main.ad_form.self_code.please_contact')"
      :self-code-for-the-further="$t('main.ad_form.self_code.for_the_further')"
      :permission="createSelfCodeBannersPermission"
      class="mb-4"
    />
    <rotation-type-field v-if="adTypeIsImage && !creativeIsSingle" class="my-6" />

    <ad-smart-threshold-field v-if="thresholdFieldIsShow" />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import AdSelfCode from '@/components/ads/AdSelfCode.vue'
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdTypeField from '@/components/ads/AdTypeField.vue'
  import AdSmartThresholdField from '@/components/ads/AdSmartThresholdField.vue'
  import permissions from '@/store/permissions.js'
  import AdCommonUrlField from '@/components/ads/AdCommonUrlField.vue'
  import Creatives from '@/components/ads/Creatives.vue'
  import RotationTypeField from '@/components/ads/RotationTypeField.vue'

  export default {
    name: 'BannerForm',
    components: {
      AdSmartThresholdField,
      AdTypeField,
      AdNameField,
      AdSelfCode,
      CSelect,
      RotationTypeField,
      AdCommonUrlField,
      Creatives
    },
    props: {
      presetedSize: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['size', 'errors', 'isDuplicate']),
      ...mapGetters('settings', ['getBannersSizes', 'bannerAdTypes']),
      ...mapGetters('adForm', [
        'creativeIsSingle',
        'adTypeIsImage',
        'adTypeIsHtml5',
        'rotationTypeIsSmart',
        'adTypeIsSelfcode',
        'getIsEditing'
      ]),
      createSelfCodeBannersPermission() {
        return permissions.CREATE_SELF_CODE_BANNERS
      },
      creativesIsShown() {
        return (this.adTypeIsImage || this.adTypeIsHtml5) && !!this.size
      },
      thresholdFieldIsShow() {
        return this.rotationTypeIsSmart && !this.creativeIsSingle && !this.adTypeIsSelfcode
      }
    },
    methods: {
      ...mapActions('adForm', ['setSize', 'removeError'])
    }
  }
</script>
