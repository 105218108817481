<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          :md="rotationIsRandom ? 9 : 12"
          :lg="rotationIsRandom ? 10 : 12"
          class="pa-0 pr-md-2"
        >
          <ad-url-field
            :selenium-id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_URL, creativeIndex)"
            :url="creative.url"
            :placeholder="$t('main.ad_form.url_placeholder')"
            :creative-index="creativeIndex"
            @input="$emit('update-creative', { value: $event, name: 'url' })"
            @focus="removeError(`creatives.${creativeIndex}.url`)"
          />
        </v-col>
        <v-col v-if="rotationIsRandom" cols="12" md="3" lg="2" class="pa-0">
          <creative-priority
            :creative-index="creativeIndex"
            :creative="creative"
            @input="$emit('update-creative', { value: $event, name: 'priority' })"
            @focus="removeError(`creatives.${creativeIndex}.priority`)"
          />
        </v-col>
      </v-row>
    </v-container>

    <ad-formats-url-tokens
      v-if="creativeTokensLinks.length"
      :value="creative.url"
      :tokens="creativeTokensLinks"
      class="mb-4"
      @input="$emit('update-creative', { value: $event, name: 'url' })"
    />

    <c-text-field
      :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_STARTING_EVENT_ENDPOINT, creativeIndex)"
      :value="creative.startingEventEndpoint"
      :error-messages="errors[`creatives.${creativeIndex}.custom_starting_event_endpoint`]"
      hide-details="auto"
      class="mb-4"
      :label="$t('main.ad_form.starting_event_endpoint')"
      :placeholder="$t('main.ad_form.starting_event_endpoint')"
      label-bold
      @input="$emit('update-creative', { value: $event, name: 'startingEventEndpoint' })"
      @focus="removeError(`creatives.${creativeIndex}.custom_starting_event_endpoint`)"
    />

    <ad-formats-url-tokens
      v-if="creativeTokensLinks.length"
      :value="creative.startingEventEndpoint"
      :tokens="creativeTokensLinks"
      class="mb-4"
      @input="$emit('update-creative', { value: $event, name: 'startingEventEndpoint' })"
    />

    <div>
      <span class="subtitle-2 black--text ma-0 pb-0 px-0">
        {{ $t('main.ad_form.loading_video') }}
      </span>
      <file-upload
        :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_VIDEO_FILEPOND, creativeIndex)"
        :value="videoId"
        store-type="media"
        dont-fetch-file
        class="mt-2"
        :label="$t('main.ad_form.upload_video', { maxVideoSize })"
        types="video/mp4"
        :max-size="maxVideoSize"
        :error-messages="errors[`creatives.${creativeIndex}.video`]"
        @update="removeError(`creatives.${creativeIndex}.video`)"
        @input="$emit('update-creative', { value: $event, name: 'video' })"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import FileUpload from '@/components/FileUpload.vue'
  import AdFormatsUrlTokens from '@/components/ads/AdFormatsUrlTokens/Index.vue'
  import AdUrlField from '@/components/ads/AdUrlField.vue'
  import CreativePriority from '@/components/ads/CreativePriority.vue'

  export default {
    name: 'InstreamVideo',
    components: {
      CTextField,
      FileUpload,
      AdFormatsUrlTokens,
      AdUrlField,
      CreativePriority
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['errors']),
      ...mapGetters('settings', ['maxVideoSize']),
      ...mapGetters('adForm', ['rotationIsRandom', 'creativeTokensLinks']),
      videoId() {
        return this.creative.video ? this.creative.video.id : null
      }
    },
    methods: {
      ...mapActions('adForm', ['removeError']),
      seleniumDynamicField
    }
  }
</script>
