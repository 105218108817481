<template>
  <v-dialog v-model="isShown" max-width="850">
    <v-card class="pb-5 pb-sm-8">
      <v-card-title class="d-block global-break-normal pb-5">
        <close-button right absolute @click="isShown = false" />

        <div class="d-flex justify-center font-weight-medium black--text pt-8 pr-5">
          {{ $t('main.ad_form.upload_urls') }}
        </div>
      </v-card-title>
      <v-card-text class="text-center pb-0 px-5 px-sm-12">
        <v-alert text color="info" icon="mdi-information-outline" class="text-left mb-0">
          <span>
            {{ $t('main.ad_form.dialog_alert') }}
          </span>
        </v-alert>
        <c-textarea :id="seleniumIds.SELENIUM_TEST_AD_FORM_UPLOAD_URLS_TEXTAREA" v-model="value" no-resize class="mt-1" rows="10" placeholder="Example1.com" />
        <c-btn
          v-show="value"
          :id="seleniumIds.SELENIUM_TEST_AD_FORM_UPLOAD_URLS_BUTTON"
          color="primary"
          large
          :label="$t('main.ad_form.create_popunders')"
          @click="submit(value)"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CTextarea from '@clickadilla/components/ui/CTextarea.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import CloseButton from '@/components/CloseButton.vue'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'PopunderUploadUrlDialog',
    components: {
      CTextarea,
      CloseButton,
      CBtn
    },
    props: {
      dialogIsShown: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        value: null,
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['name', 'creatives']),
      isShown: {
        get() {
          return this.dialogIsShown
        },
        set() {
          this.$emit('close')
        }
      }
    },
    methods: {
      ...mapActions('adForm', ['setCreatives']),
      async submit(value) {
        const urlLinks = value.trim().split('\n')
        this.setCreatives(
          urlLinks.map((url) => ({
            url,
            priority: 100
          }))
        )
        gtmPush({
          event: GTM_EVENTS.GENERATE_MULTIPOP,
          event_category: GTM_EVENTS_CATEGORY.CREATED_AD
        })
        this.isShown = false
      }
    }
  }
</script>
