<template>
  <div>
    <span class="black--text opacity-40 body-2">{{ label }}</span>
    <v-menu offset-y :close-on-content-click="false" max-width="300">
      <template #activator="{ on }">
        <v-layout align-end>
          <div class="mb-2 color-circle" :style="`background: ${color}`" v-on="on" />
          <v-text-field v-model="color" class="mt-0 pt-0" v-bind="$attrs" hide-details v-on="on" />
        </v-layout>
      </template>

      <v-color-picker v-model="color" />
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'ColorPicker',
    inheritAttrs: false,
    props: {
      value: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      }
    },
    computed: {
      color: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: var(--v-secondary-base);
  }
  .color-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid var(--v-secondary-darken-base);
    margin-right: 10px;
  }
  ::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
    width: 60% !important;
  }
  ::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
    width: 60% !important;
  }
  ::v-deep .v-input input {
    max-height: 30px;
  }
</style>
