var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[{ 'position-sticky-preview': !_vm.previewIsAnchor }]},[(_vm.previewIsFullscreen)?_c('interstitials-preview-dialog',{attrs:{"preview-is-fullscreen":_vm.previewIsFullscreen,"frame-url":_vm.frameUrl},on:{"close-dialog":function($event){_vm.previewIsFullscreen = false}}}):_vm._e(),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.previewIsFullscreen),expression:"!previewIsFullscreen"}],staticClass:"black pa-3 pa-sm-8 pt-sm-0",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"d-flex align-center justify-center px-0 pt-1 pb-2 py-sm-4"},[_c('c-btn',{staticClass:"mx-2",attrs:{"icon-props":{
          size: 20,
          color: _vm.previewColorFullscreen,
          icon: '$full-screen'
        },"icon":""},on:{"click":function($event){_vm.previewIsFullscreen = true}}}),_c('c-btn',{staticClass:"mx-2",attrs:{"icon-props":{
          size: 20,
          color: _vm.previewColorAnchor,
          icon: '$anchor'
        },"icon":""},on:{"click":function($event){_vm.previewIsAnchor = !_vm.previewIsAnchor}}})],1),_c('interstitials-preview-frame',{attrs:{"frame-url":_vm.frameUrl}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }