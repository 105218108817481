<template>
  <v-radio-group v-model="selectedAgent" mandatory class="ma-0">
    <div v-for="option in optionsPlatform" :key="option.value" class="mb-3">
      <v-radio color="primary" :value="option.value">
        <template #label>
          <span class="white--text">{{ option.text }}</span>
        </template>
      </v-radio>

      <c-toggle-buttons
        v-if="option.btnOptions"
        v-model="platformSelected"
        :value="platformSelected"
        :buttons="option.btnOptions"
        :disabled="selectedAgent !== option.value"
        :button-attrs="{
          color: 'transparent',
          class: 'white--text pl-2 text-caption'
        }"
        background-color="transparent"
        mandatory
      />
    </div>
  </v-radio-group>
</template>

<script>
  import CToggleButtons from '@clickadilla/components/ui/CToggleButtons.vue'

  export default {
    name: 'AgentSelect',
    components: { CToggleButtons },
    props: {
      value: {
        type: String,
        required: true
      },
      optionsPlatform: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        platformSelected: null
      }
    },
    computed: {
      selectedAgent: {
        get() {
          return this.value
        },
        set(value) {
          if (value === 'windows') this.platformSelected = 'win7810pro'
          if (value === 'android') this.platformSelected = 'expanded'

          this.$emit('input', value)
        }
      }
    },
    watch: {
      platformSelected() {
        this.$emit('platform-handler', this.platformSelected)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-radio ::v-deep .v-icon {
    color: var(--v-white-base);
  }
  ::v-deep .theme--light.v-btn.v-btn--disabled {
    color: var(--v-white-base) !important;
  }
</style>
