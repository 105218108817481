<template>
  <v-tooltip bottom color="black" :disabled="isMobile">
    <template #activator="{ on, attrs }">
      <a v-if="link || !isMobile" v-bind="attributeHref" class="text-decoration-none" target="_blank">
        <v-hover v-slot="{ hover }">
          <v-icon v-bind="attrs" size="18" :color="hover ? 'info-lighten' : 'info'" v-on="on">
            mdi-information-outline
          </v-icon>
        </v-hover>
      </a>
    </template>
    <div class="pa-4">
      <slot />
    </div>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'CreativeInfoIcon',
    props: {
      link: {
        type: String,
        default: ''
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      attributeHref() {
        return this.link ? { href: this.link } : {}
      }
    }
  }
</script>
