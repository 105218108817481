<template>
  <div>
    <a class="text-subtitle-2 text-decoration-underline" @click.stop="dialogIsShown = true">
      {{ $t('main.ad_form.load_list') }}
    </a>

    <popunder-upload-url-dialog
      :dialog-is-shown="dialogIsShown"
      :creative="creatives"
      @close="dialogIsShown = false"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import PopunderUploadUrlDialog from '@/components/ads/PopunderForm/PopunderUploadUrlDialog.vue'

  export default {
    name: 'MultiPopunder',
    components: {
      PopunderUploadUrlDialog
    },
    data() {
      return {
        dialogIsShown: false
      }
    },
    computed: {
      ...mapState('adForm', ['creatives'])
    }
  }
</script>
