<template>
  <div class="windows-home-push">
    <div
      v-if="creative.image.src"
      class="windows-home-push__image full-width"
      :style="{
        backgroundImage: `url(${creative.image.src})`
      }"
    />
    <div class="windows-home-push__container full-width">
      <div class="windows-home-push__icon">
        <div
          class="windows-home-push__icon-img"
          :style="{
            backgroundImage: `url(${creative.icon.src})`
          }"
        />
      </div>
      <div class="windows-home-push__content">
        <div class="windows-home-push__content-title">
          {{ creative.title }}
        </div>
        <div class="windows-home-push__content-description">
          {{ creative.body || creative.description }}
        </div>
        <div class="windows-home-push__content-origin">
          <div class="windows-home-push__content-browser">
            Google.chrome
          </div>
          <div class="windows-home-push__content-site">
            {{ creative.url }}
          </div>
        </div>
      </div>
    </div>
    <v-row class="black white--text full-width ma-0">
      <v-col v-if="buttonsAreShown && creative.buttonOne" cols="6">
        <c-btn
          :label="creative.buttonOne"
          class="secondary-darken white--text text-truncate rounded-0 full-width"
          depressed
          block
          text
        />
      </v-col>
      <v-col v-if="buttonsAreShown && creative.buttonTwo" cols="6">
        <c-btn
          :label="creative.buttonTwo"
          class="secondary-darken white--text text-truncate rounded-0 full-width"
          depressed
          block
          text
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'WindowsHomePush',
    components: {
      CBtn
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      buttonsAreShown: {
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-btn__content {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 100%;
    display: inline-block;
  }
  .full-width {
    width: 100%;
  }
  .windows-home-push {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 330px;
    max-width: 100%;

    &_animated {
      transform: translate(0);
      transition: transform 0.4s ease;
    }
    &__image {
      height: 185px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    &__container {
      display: flex;
      flex-direction: row;
      padding: 10px 30px 0 10px;
      position: relative;
      background-color: var(--v-black-base);
      min-height: 70px;
      letter-spacing: 0.18px;
    }
    &__icon-img {
      width: 50px;
      flex-shrink: 0;
      height: 50px;
      margin-right: 10px;
      border-radius: 3px;
      background-size: cover;
      background-position: center center;
    }
    &__content {
      display: flex;
      flex-direction: column;
      letter-spacing: 0.3px;
      width: 320px;
      max-width: calc(100% - 40px);

      &-description,
      &-title {
        max-width: 230px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
      }
      &-description {
        margin-bottom: 6px;
        color: var(--v-secondary-base);
      }
      &-title {
        font-size: 12px;
        margin-bottom: 2px;
        color: var(--v-white-base);
        font-weight: 500;
        line-height: 1;
      }
      &-origin {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 210px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: var(--v-secondary-base);
      }
      &-browser {
        margin-right: 6px;
        font-size: 12px;
      }
      &-site {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 120px;
        font-size: 12px;

        &::before {
          content: '';
          position: relative;
          top: -2px;
          display: inline-block;
          min-width: 2px;
          height: 2px;
          background: var(--v-secondary-base);
          margin-right: 6px;
          border-radius: 2px;
        }
      }
    }
  }
</style>
