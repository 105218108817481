<template>
  <v-btn
    class="text-subtitle-2 px-8 text-capitalize"
    v-bind="$attrs"
    :color="color"
    large
    :loading="saveIsLoading"
    @click="$emit('click')"
  >
    {{ $t('main.save') }}
  </v-btn>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'SaveCreativeButton',
    props: {
      color: {
        type: String,
        default: 'primary'
      }
    },
    computed: {
      ...mapState('adForm', ['saveIsLoading'])
    }
  }
</script>
