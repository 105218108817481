<template>
  <div class="d-flex flex-column flex-sm-row align-sm-center">
    <c-toggle-buttons
      v-model="selectedRotationType"
      :title="$t('main.ad_form.smart_rotation')"
      :buttons="getRotationTypes"
      :disabled="creatives.length <= 1"
      :error-messages="errors.rotation_type"
    />
    <div class="text-caption info-lighten--text ml-0 ml-sm-5 pt-5">
      {{ $t('main.ad_form.alert_smart_rotation') }}
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex/dist/vuex.esm.browser.js'
  import CToggleButtons from '@clickadilla/components/ui/CToggleButtons.vue'

  export default {
    name: 'RotationsFiled',
    components: {
      CToggleButtons
    },
    computed: {
      ...mapState('adForm', ['rotationType', 'creatives', 'errors']),
      ...mapGetters('settings', ['getRotationTypes']),
      selectedRotationType: {
        get() {
          return this.rotationType
        },

        set(value) {
          this.setRotationType(value)
        }
      }
    },
    watch: {
      creatives(value) {
        if (value.length <= 1) {
          this.selectedRotationType = ''
        }
      }
    },
    methods: {
      ...mapActions('adForm', ['setRotationType'])
    }
  }
</script>
