<template>
  <c-text-field
    :id="seleniumIds.SELENIUM_TEST_AD_FORM_BRAND"
    :value="brand"
    :error-messages="errors.brand"
    :placeholder="$t('main.ad_form.enter_a_name')"
    hide-details="auto"
    class="mb-3"
    background-color="white"
    :label="$t('main.brand')"
    label-bold
    @input="setBrand($event)"
    @focus="removeError('brand')"
  />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'AdBrandField',
    components: {
      CTextField
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['brand', 'errors'])
    },
    methods: {
      ...mapActions('adForm', ['setBrand', 'removeError'])
    }
  }
</script>
