<template>
  <v-container>
    <v-row>
      <v-col v-for="token in tokens" :key="token" cols="6" sm="auto" class="pa-1">
        <v-chip
          :large="isMobile"
          label
          :dark="selectedTokens.includes(token)"
          :color="selectedTokens.includes(token) ? 'black' : chipColor"
          :class="[
            'd-block text-center',
            {
              'text-body-2': isMobile
            }
          ]"
          @click="toggleToken(token)"
        >
          <span
            :class="[
              'text-truncate text-lowercase',
              {
                'black--text': !selectedTokens.includes(token)
              }
            ]"
          >
            {{ token }}
          </span>
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import getQueryParams from '@/services/utils/get-query-params.js'

  export default {
    name: 'UrlTokensQueryBuilder',
    props: {
      value: {
        type: String,
        default: ''
      },
      tokens: {
        type: Array,
        default: () => []
      },
      isLight: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      Value: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      },
      chipColor() {
        return this.isLight ? 'secondary-lighten' : ''
      },
      queryParams: {
        get() {
          return getQueryParams(this.Value)
        },
        set(val) {
          const url = this.Value.split('?')[0]
          this.Value = Object.entries(val).reduce((acc, [key, value], index) => {
            if (+index === 0) {
              acc += '?'
            } else {
              acc += '&'
            }

            acc += `${key}=${value}`

            return acc
          }, url)
        }
      },
      selectedTokens() {
        return this.tokens.reduce((acc, token) => {
          const index = this.Value.indexOf(`[${token}]`)

          if (index !== -1) {
            acc.push(token)
          }

          return acc
        }, [])
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    methods: {
      toggleToken(token) {
        if (this.selectedTokens.includes(token)) {
          this.removeToken(token)
        } else {
          this.addToken(token)
        }
      },
      addToken(token) {
        this.queryParams = {
          ...this.queryParams,
          [token.toLowerCase()]: `[${token}]`
        }
      },
      removeToken(token) {
        this.queryParams = Object.entries(this.queryParams).reduce((acc, [key, value]) => {
          if (value !== `[${token}]`) {
            acc[key] = value
          }

          return acc
        }, {})
      }
    }
  }
</script>
