<template>
  <div>
    <ad-name-field />

    <c-text-field
      :id="seleniumIds.SELENIUM_TEST_AD_FORM_CONTENT_FEED_NAME"
      :value="contentFeedName"
      :error-messages="errors.content_feed_name"
      :label="$t('main.ad_form.content_feed_name')"
      :placeholder="$t('main.ad_form.enter_a_content_feed_name')"
      :hint="`${$t('main.ad_form.max')}: ${maxSymbol.contentFeedName} ${$t('main.ad_form.characters')}`"
      class="pt-1"
      hide-details="auto"
      :counter="maxSymbol.contentFeedName"
      :maxlength="maxSymbol.contentFeedName"
      label-bold
      background-color="white"
      @input="setContentFeedName($event)"
      @focus="removeError('content_feed_name')"
    />

    <ad-url-field
      :selenium-id="seleniumIds.SELENIUM_TEST_AD_FORM_URL"
      :url="contentFeedUrl"
      :placeholder="$t('main.ad_form.enter_the_url_of_the_feed')"
      is-not-creative
      background-color="white"
      @input="setContentFeedUrl($event)"
      @focus="removeError('content_feed_url')"
    />

    <c-text-field
      :id="seleniumIds.SELENIUM_TEST_AD_FORM_PROVIDER_WEBSITE"
      :value="providerWebsite"
      :error-messages="errors.provider_website"
      :label="$t('main.ad_form.provider_website')"
      :placeholder="$t('main.ad_form.enter_vendor_website')"
      hide-details="auto"
      label-bold
      background-color="white"
      class="my-2"
      @input="setProviderWebsite($event)"
      @focus="removeError('provider_website')"
    />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdUrlField from '@/components/ads/AdUrlField.vue'

  export default {
    name: 'GalleryAdForm',
    components: {
      AdNameField,
      CTextField,
      AdUrlField
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['contentFeedName', 'contentFeedUrl', 'providerWebsite', 'errors']),
      ...mapState('settings', {
        maxSymbol: (state) => state.adsDataRequirements.gallery
      })
    },
    methods: {
      ...mapActions('adForm', [
        'setContentFeedName',
        'setContentFeedUrl',
        'setProviderWebsite',
        'removeError'
      ])
    }
  }
</script>
