<template>
  <expansion-panel-creative
    :title="$t('main.ad_form.creative')"
    :removable="removable"
    :creative="creative"
    :creative-index="creativeIndex"
  >
    <template #content>
      <v-card-text class="pa-6">
        <ad-url-field
          :selenium-id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_URL, creativeIndex)"
          :url="creative.url"
          :creative-index="creativeIndex"
          :placeholder="$t('main.ad_form.url_placeholder')"
          @input="setCreative($event, 'url')"
          @focus="removeError([`creatives.${creativeIndex}.url`, `creatives.url`])"
        />

        <div v-if="creative.url && !adTypeIsLanding" class="d-flex align-center mb-3">
          <c-info-btn class="mr-2" />
          <span class="info--text">{{ $t('main.ad_form.landing_type_question') }}</span>
          <v-hover v-slot="{ hover }">
            <a
              :class="[{ 'text-decoration-underline': !hover }, 'primary--text ml-3']"
              @click="setTypeIsLanding"
            >
              {{ $t('main.agree') }}
            </a>
          </v-hover>
        </div>

        <ad-formats-url-tokens
          v-if="creativeTokensLinks.length"
          :value="creative.url"
          :tokens="creativeTokensLinks"
          class="mb-4"
          @input="setCreative($event, 'url')"
        />

        <file-upload
          v-if="!adTypeIsLanding"
          :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_IMAGE_FILEPOND, creativeIndex)"
          ref="fileUpload"
          :value="imageId"
          :label="label"
          types="image/jpeg, image/png, image/gif, video/mp4"
          :max-size="maxVideoSize"
          store-type="media"
          :error-messages="errors[`creatives.${creativeIndex}.image`] || errors[`creatives.image`]"
          :crop-size="size"
          @update="removeError([`creatives.${creativeIndex}.image`, `creatives.image`])"
          @input="setCreative($event, 'image')"
        />
        <interstitials-preview v-else :frame-url="creative.url" />

        <v-alert
          v-if="isMobileSize && adTypeIsImage"
          text
          type="info"
          icon="mdi-information-outline"
          color="info"
        >
          <span>{{ $t('main.ad_form.recommendation_message') }}</span>
        </v-alert>
      </v-card-text>

      <v-card-actions v-if="isFinalElement && !getIsEditing" class="pa-6 pt-0">
        <c-btn
          :id="seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_ADD"
          color="primary"
          outlined
          large
          :block="isMobile"
          :label="$t('main.ad_form.add')"
          :icon-props="{
            icon: '$plus',
            size: '14'
          }"
          @click="addCreative()"
        />
      </v-card-actions>
    </template>
  </expansion-panel-creative>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CInfoBtn from '@clickadilla/components/ui/CInfoBtn.vue'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import { adTypes } from '@clickadilla/components/constants/ad-types.js'
  import { interstitialSizes } from '@clickadilla/components/constants/ad-sezes.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import FileUpload from '@/components/FileUpload.vue'
  import AdFormatsUrlTokens from '@/components/ads/AdFormatsUrlTokens/Index.vue'
  import ExpansionPanelCreative from '@/components/ads/CreativeLayout/ExpansionPanelCreative.vue'
  import AdUrlField from '@/components/ads/AdUrlField.vue'
  import InterstitialsPreview from '@/components/ads/InterstitialsForm/InterstitialsPreview.vue'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'InterstitialCreative',
    components: {
      FileUpload,
      AdFormatsUrlTokens,
      ExpansionPanelCreative,
      AdUrlField,
      CBtn,
      InterstitialsPreview,
      CInfoBtn
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: [Number, String],
        required: true
      },
      removable: {
        type: Boolean,
        defaults: false
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['size', 'errors', 'creatives', 'adFormatType']),
      ...mapGetters('settings', ['maxVideoSize', 'maxImageSize']),
      ...mapGetters('adForm', [
        'getIsEditing',
        'adTypeIsImage',
        'adTypeIsSelfcode',
        'adTypeIsLanding',
        'creativeTokensLinks'
      ]),
      label() {
        return `JPG, PNG, GIF. ${this.$t('main.ad_form.size_maximum', {
          size: this.maxImageSize
        })} | ${this.size}
                </br> MP4. ${this.$t('main.ad_form.size_maximum', { size: this.maxVideoSize })}`
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isFinalElement() {
        return this.creativeIndex === this.creatives.length - 1
      },
      imageId() {
        return this.creative.image ? this.creative.image.id : null
      },
      isMobileSize() {
        return this.size === interstitialSizes.MOBILE_SIZE
      }
    },
    methods: {
      ...mapActions('adForm', ['removeError', 'addCreative', 'setType']),
      setCreative(value, name) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        creativeCopy[name] = value
        this.$emit('update-creative', creativeCopy)
      },
      setTypeIsLanding() {
        this.setType(adTypes.LANDING)
        gtmPush({
          event: GTM_EVENTS.GENERATE_LINK_CREATIVE,
          event_category: GTM_EVENTS_CATEGORY.CREATED_AD
        })
      },
      seleniumDynamicField
    }
  }
</script>
