<template>
  <div>
    <div class="d-flex align-center mt-6">
      <span class="text-subtitle-2 black--text text-center pr-2">{{ $t('main.ad_form.common_url') }}</span>
      <creative-info-icon :link="helpUrls.token_for_tracking">
        <url-requirement />
      </creative-info-icon>
    </div>

    <c-text-field
      :id="seleniumIds.SELENIUM_TEST_AD_FORM_COMMON_URL"
      :value="commonUrl"
      :error-messages="errors.url"
      background-color="white"
      :placeholder="$t('main.ad_form.url_placeholder')"
      :placholder="$t('main.ad_form.common_url')"
      label-bold
      hide-details="auto"
      @input="setCommonUrl($event)"
      @focus="removeError('url')"
    />
    <v-expand-transition>
      <div v-if="errors.url">
        <url-requirement
          class="mt-3 mb-6 py-3 px-4 rounded info--text"
          info-theme
        />
      </div>
    </v-expand-transition>
    <ad-formats-url-tokens
      v-if="creativeTokensLinks.length"
      :value="commonUrl"
      :tokens="creativeTokensLinks"
      class="mb-4"
      :is-light="false"
      @input="setCommonUrl($event)"
    />
  </div>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import AdFormatsUrlTokens from '@/components/ads/AdFormatsUrlTokens/Index.vue'
  import CreativeInfoIcon from '@/components/CreativeInfoIcon.vue'
  import UrlRequirement from '@/components/ads/UrlRequirement.vue'

  export default {
    name: 'AdCommonUrlField',
    components: {
      UrlRequirement,
      CreativeInfoIcon,
      AdFormatsUrlTokens,
      CTextField
    },
    props: {
      adFormatType: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['commonUrl', 'errors']),
      ...mapGetters('settings', ['helpUrls']),
      ...mapGetters('adForm', ['creativeTokensLinks'])
    },
    methods: {
      ...mapActions('adForm', ['setCommonUrl', 'removeError'])
    }
  }
</script>
