<template>
  <expansion-panel-creative
    :title="$t('main.ad_form.creative')"
    :removable="removable"
    :creative="creative"
    :creative-index="creativeIndex"
  >
    <template #content>
      <v-card-text v-if="adTypeIsHtml5" class="pa-6">
        <file-upload
          :id="seleniumDynamicFields(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_HTML5_FILEPOND, creativeIndex)"
          ref="fileUpload"
          :key="`package_${creativeIndex}`"
          :value="packageId"
          :label="zipUploadLabel"
          types="application/zip, application/x-zip-compressed"
          store-type="media"
          :max-size="maxZipSize"
          :error-messages="errors[`creatives.${creativeIndex}.package`] || errors[`creatives.package`]"
          @update="removeError([`creatives.${creativeIndex}.package`, `creatives.package`])"
          @input="setCreative($event, 'package')"
        />
        <v-alert v-if="helpUrls.html5_banners" color="info" text class="mt-6">
          {{ $t('main.ad_form.html5_alert.text') }}
          <a :href="helpUrls.html5_banners" target="_blank" class="info--text">
            {{ $t('main.ad_form.html5_alert.link') }}
          </a>
        </v-alert>
      </v-card-text>
      <v-card-text v-else class="pa-6">
        <ad-url-field
          :selenium-id="seleniumDynamicFields(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_URL, creativeIndex)"
          :url="creative.url"
          :creative-index="creativeIndex"
          :placeholder="$t('main.ad_form.url_placeholder')"
          @input="setCreative($event, 'url')"
          @focus="removeError([`creatives.${creativeIndex}.url`, `creatives.url`])"
        />

        <ad-formats-url-tokens
          v-if="creativeTokensLinks.length"
          :value="creative.url"
          :tokens="creativeTokensLinks"
          class="mb-3"
          @input="setCreative($event, 'url')"
        />

        <file-upload
          :id="seleniumDynamicFields(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_IMAGE_FILEPOND, creativeIndex)"
          ref="fileUpload"
          :key="`image_${creativeIndex}`"
          :value="imageId"
          :label="label"
          types="image/jpeg, image/png, image/gif, image/svg+xml, video/mp4"
          store-type="media"
          :max-size="maxVideoSize"
          :crop-size="size"
          :error-messages="errors[`creatives.${creativeIndex}.image`] || errors[`creatives.image`]"
          @update="removeError([`creatives.${creativeIndex}.image`, `creatives.image`])"
          @input="setCreative($event, 'image')"
        />
      </v-card-text>

      <v-card-actions v-if="addCreativeIsShown" class="pa-6 pt-0">
        <c-btn
          :id="seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_ADD"
          color="primary"
          outlined
          large
          :block="isMobile"
          :label="$t('main.ad_form.add')"
          :icon-props="{
            icon: '$plus',
            size: '14'
          }"
          @click="addCreative()"
        />
      </v-card-actions>
    </template>
  </expansion-panel-creative>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicFields from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import FileUpload from '@/components/FileUpload.vue'
  import AdFormatsUrlTokens from '@/components/ads/AdFormatsUrlTokens/Index.vue'
  import ExpansionPanelCreative from '@/components/ads/CreativeLayout/ExpansionPanelCreative.vue'
  import AdUrlField from '@/components/ads/AdUrlField.vue'

  export default {
    name: 'BannerCreative',
    components: {
      FileUpload,
      AdFormatsUrlTokens,
      CBtn,
      ExpansionPanelCreative,
      AdUrlField
    },
    props: {
      removable: Boolean,
      creativeIndex: {
        type: Number,
        required: true
      },
      creative: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['size', 'errors', 'creatives']),
      ...mapGetters('settings', ['maxVideoSize', 'maxImageSize', 'maxZipSize', 'helpUrls']),
      ...mapGetters('adForm', ['getIsEditing', 'adTypeIsHtml5', 'creativeTokensLinks']),
      imageId() {
        return this.creative.image ? this.creative.image.id : null
      },
      packageId() {
        return this.creative.package ? this.creative.package.id : null
      },
      label() {
        return `JPG, PNG, GIF, SVG. ${this.$t('main.ad_form.size_maximum', {
          size: this.maxImageSize
        })} | ${this.size}
                </br> MP4. ${this.$t('main.ad_form.size_maximum', { size: this.maxVideoSize })}`
      },
      zipUploadLabel() {
        return `ZIP. ${this.$t('main.ad_form.size_maximum', { size: this.maxZipSize })} | ${this.size}`
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isFinalElement() {
        return this.creativeIndex === this.creatives.length - 1
      },
      addCreativeIsShown() {
        return this.isFinalElement
      }
    },
    methods: {
      seleniumDynamicFields,
      ...mapActions('adForm', ['removeError', 'addCreative']),
      setCreative(value, name) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        creativeCopy[name] = value
        this.$emit('update-creative', creativeCopy)
      }
    }
  }
</script>
