<template>
  <div>
    <ad-name-field />
    <ad-type-field
      class="mb-4"
      :ad-types="filteredInStreamAdTypes"
      :title="$t('main.ad_form.select_instream_type')"
    />
    <select-skip-time v-if="hasSkipTime" class="mb-4" />
    <ad-priority v-if="rotationIsRandom" />
    <creatives ref="creatives" />
    <rotation-type-field v-if="!creativeIsSingle" class="my-6" />
    <ad-smart-threshold-field v-if="rotationTypeIsSmart && !creativeIsSingle" />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { adTypes } from '@clickadilla/components/constants/ad-types.js'
  import AdNameField from '@/components/ads/AdNameField.vue'
  import AdTypeField from '@/components/ads/AdTypeField.vue'
  import SelectSkipTime from '@/components/ads/InstreamForm/SelectSkipTime.vue'
  import RotationTypeField from '@/components/ads/RotationTypeField.vue'
  import AdPriority from '@/components/ads/AdPriority.vue'
  import AdSmartThresholdField from '@/components/ads/AdSmartThresholdField.vue'
  import Creatives from '@/components/ads/Creatives.vue'

  export default {
    name: 'InstreamForm',
    components: {
      AdNameField,
      AdTypeField,
      SelectSkipTime,
      AdPriority,
      Creatives,
      RotationTypeField,
      AdSmartThresholdField
    },
    props: {
      presetedAdTypes: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapGetters('settings', ['inStreamAdTypes']),
      ...mapGetters('adForm', ['rotationIsRandom', 'rotationTypeIsSmart', 'creativeIsSingle']),
      ...mapState('adForm', ['type']),
      hasSkipTime() {
        return this.type !== adTypes.REWARDED_POST
      },
      filteredInStreamAdTypes() {
        return this.presetedAdTypes.length
          ? this.inStreamAdTypes.filter((adType) => this.presetedAdTypes.includes(adType))
          : this.inStreamAdTypes
      }
    }
  }
</script>
