<template>
  <iframe ref="inPagePreviewRef" :height="height" width="100%" class="preview-iframe" />
</template>

<script>
  export default {
    name: 'InPagePreview',
    props: {
      settings: {
        type: Object,
        required: true
      },
      height: {
        type: [Number, String],
        default: '330'
      },
      tagId: {
        type: Number,
        required: true
      }
    },
    mounted() {
      if (this.settings.icon) {
        this.view()
      }
    },
    methods: {
      view() {
        const iframeContent = this.$refs.inPagePreviewRef.contentWindow
        const script = iframeContent.document.createElement('script')
        script.async = true
        script.src = 'https://js.wpadmngr.com/static/adManager.m.js'
        script.onload = async () => {
          const config = await iframeContent.window.AdManager.loadTagConfig(this.tagId)
          const configWithHelpers = iframeContent.window.AdManager.getConfigHelpers(config)
          const originalFormat = configWithHelpers.findFormat('inpage')
          originalFormat.spots[0].config = {
            sub_id: 733721688,
            spot_id: 111111,
            unified_id: 400009280,
            save_spot_id: false,
            tube: 'native-push',
            proxy_domain: 'https://vidvas3.com',
            auction_url: 'https://adclhome.com/ass/debug/page_for_shows/api.php',
            type: 1,
            mtype: 1,
            disabled: 0,
            target: 0,
            ml_close_ratio: 100,
            ml_close_ratio_modal: 100,
            eventFrequency: {
              type: 'show',
              count: 1,
              cappingTime: 0
            },
            perPageEventsFrequency: {
              show: 1
            },
            firstShowEvent: {
              type: 'page',
              delay: 0
            },
            betweenShowEventsDelay: 0,
            betterAds: false,
            betterAdsMobile: false,
            branding: true,
            startOnFullscreenEnd: true,
            paramsToExtend: {
              type: 1,
              body: this.settings.description,
              skin_id: this.settings.tdsId,
              creative_title: this.settings.title,
              icon: this.settings.icon,
              image: this.settings.image
            },
            tabunder: true,
            large_cross_button: false,
            is_position_locked: true,
            is_notify_animation: false,
            placements_with_outside_close: [

            ],
            suggestive: false,
            appearance_event: {
              type: 'default',
              anchor_selector: null
            },
            is_url_hashing: false,
            blocked_verticals: [

            ]
          }
          originalFormat.assets.js = ['https://adclhome.com/ass/debug/inpage/builds/test-skins-for-stand-CA.m.js?v=1699515596205']
          iframeContent.window.AdManager.init((config), () => {
            iframeContent.window.AdManager.providedDependencies.hashGenerator = null
          })
        }
        iframeContent.document.body.append(script)
      }
    }
  }
</script>

<style scoped lang="scss">
  .preview-iframe {
    border: unset;
    border-radius: 0 0 4px 4px;
    background-color: rgb(245 245 245 / 0.05);
  }
</style>
