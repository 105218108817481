<template>
  <div class="align-center pt-0 pb-4">
    <v-icon size="25" color="info-lighten" class="pr-3">
      mdi-information-outline
    </v-icon>
    <span class="info--text">
      {{ $t('main.ad_form.file_fields_description') }}

      <strong class="pr-3" @click.prevent="onSuccess()">
        <a href="#">
          {{ $t('main.agree') }}
        </a>
      </strong>
    </span>
  </div>
</template>

<script>
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'AdsAutoComplete',
    props: {
      creativeUrl: {
        type: String,
        default: ''
      }
    },
    methods: {
      onSuccess() {
        this.$emit('auto-complete')
        gtmPush({
          event: GTM_EVENTS.GENERATE_IN_PAGE,
          event_category: GTM_EVENTS_CATEGORY.CREATED_AD
        })
      }
    }
  }
</script>
