<template>
  <c-text-field
    :value="creative.priority"
    :error-messages="errors[`creatives.${creativeIndex}.priority`]"
    placeholder="0"
    type="number"
    min="1"
    step="1"
    :disabled="isSingleCreative"
    @input="$emit('input', $event)"
    @focus="$emit('focus')"
  >
    <template #label>
      <div class="d-flex align-center text-subtitle-2 black--text mb-2 mb-sm-1">
        <span class="mr-2">
          {{ $t('main.ad_form.priority') }}
        </span>

        <creative-info-icon>
          <span>
            {{ $t('main.ad_form.tooltips_priority') }}
          </span>
        </creative-info-icon>
      </div>
    </template>
  </c-text-field>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapState } from 'vuex'
  import CreativeInfoIcon from '@/components/CreativeInfoIcon.vue'

  export default {
    name: 'CreativePriority',
    components: {
      CreativeInfoIcon,
      CTextField
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapState('adForm', ['creatives', 'errors']),
      isSingleCreative() {
        return this.creatives.length === 1
      }
    }
  }
</script>
